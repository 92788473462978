import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { AssetFieldActions } from '../actions/asset-field.actions';
import { AssetField } from '../models/asset-field.model';
import { AssetFieldService } from 'src/app/services/asset-field.service';
import { StorageService } from 'src/app/services/storage.service';

export class AssetFieldStateModel {
    is_refreshing: boolean
    asset_field: AssetField[]
}

@State<AssetFieldStateModel>({
    name: 'asset_field',
    defaults: {
        is_refreshing: true,
        asset_field: []
    }
})
@Injectable()
export class AssetFieldState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private asset_fieldService: AssetFieldService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<AssetFieldStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                       
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }   

    async ngxsOnChanges(change: NgxsSimpleChange) {                     
        if (this.storageService.is_initialized) {
            const state_model: AssetFieldStateModel = change.currentValue
            this.storageService.set('asset_fields', JSON.stringify(state_model))   
        }  
    }

    async reloadStateFromStorage() {        
        const json: string = await this.storageService.get('asset_fields');        
        if (json) {
            const state_model: AssetFieldStateModel = JSON.parse(json)            
            if (state_model) {                
                this.store.dispatch(new AssetFieldActions.Init(state_model));
                return
            } 
        }
        this.store.dispatch(new AssetFieldActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.asset_fieldService.getAll()    
        if (objs != null) {
          const many = AssetField.buildMany(objs)                  
          this.store.dispatch(new AssetFieldActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: AssetFieldStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: AssetFieldStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: AssetFieldStateModel) {
        return state.asset_field
    }

    @Action(AssetFieldActions.Refresh)
    async refresh({patchState}: StateContext<AssetFieldStateModel>, {  }:AssetFieldActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(AssetFieldActions.Init)
    init({setState, getState}: StateContext<AssetFieldStateModel>, { payload }:AssetFieldActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new AssetFieldActions.Refresh())
    }
    
    @Action(AssetFieldActions.Set)
    set({setState}: StateContext<AssetFieldStateModel>, { payload }:AssetFieldActions.Set) {             
        setState({   
            is_refreshing: false,         
            asset_field: payload
        })
    }
}
