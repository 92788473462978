import { Asset } from "../models/asset.model";
import { AssetStateModel } from "../state/asset.state";

export namespace AssetActions {
    export class Refresh {
        static readonly type = '[API-Asset] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Asset] Init'
        constructor(public payload: AssetStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Asset] Set'
        constructor(public payload: Asset[]) {}
    }
}
