import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { User } from "src/app/state-man/models/user.model";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  static setUser(user: User) {
    Bugsnag.setUser(user.id.toString(), user.email, user.name);
  }

  static log(error: any) {
    if (!error) {
      error = 'General app error occurred.';
    }
    Bugsnag.notify(error);
  }
}
