import { Pto } from "../models/pto.model";
import { PtoStateModel } from "../state/pto.state";

export namespace PtoActions {
    export class Refresh {
        static readonly type = '[API-Pto] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Pto] Init'
        constructor(public payload: PtoStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Pto] Set'
        constructor(public payload: Pto) {}
    }
}
