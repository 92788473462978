import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { JsaPhoto } from '../state-man/models/jsa-photo.model';
import { PhotoService } from './photo.service';
import { Jsa } from '../state-man/models/jsa.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})

export class JsaService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
    ) {
  }

  public async getScheduled () : Promise<Jsa[] | null> {   
    try {      
      const ret = await this.apiService.apiReturnsAny('get', 'jsa/scheduled/')        
      return (ret && Array.isArray(ret)) ? Jsa.buildMany(ret) : null      
    } catch (error) {
      LoggerService.log(error + 'jsa/scheduled/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async getPhotosByJsaId (jsa_id: number) : Promise<JsaPhoto[] | null> {
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'jsa_photo/jsa/' + jsa_id)          
      return (ret && Array.isArray(ret)) ? JsaPhoto.buildMany(ret) : null
    } catch (error) {
      LoggerService.log(error + 'jsa_photo/jsa/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }
  }

  public async getPhotoById (id: number) : Promise<JsaPhoto | null> {   
    try {
      const ret = await await this.apiService.apiReturnsAny('get', 'jsa_photo/' + id)          
      return (ret && Array.isArray(ret)) ? JsaPhoto.buildOne(ret) : null
    } catch (error) {
      LoggerService.log(error + 'jsa_photo/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }
  }

  public async addPhoto (jsa: Jsa, photo: JsaPhoto) : Promise<JsaPhoto | null> {
    try {
      const body = new FormData()
      const base64Image = photo.base64_sz.split(';base64,').pop()
      const blob = PhotoService.b64toBlob(base64Image, photo.file_type)
      body.append('media_file', blob, 'media_file')
      body.append('remove_bg', jsa.type === 'JSA' ? '1' : '0')
      body.append('is_completed', jsa.type === 'JSA' ? '1' : '0')
      return await this.apiService.apiReturnsAny('post', '/jsa/media/' + jsa.id, body, 'multipart/form-data')          
    } catch (error) {
      LoggerService.log(error + '/jsa/media/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }
  }

  public async deletePhoto(photo: JsaPhoto) : Promise<any | null> {
    try {
      return await this.apiService.apiReturnsAny('delete', '/jsa_photo/' + photo.id)
    } catch (error) {
      LoggerService.log(error + 'delete /jsa_photo/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }
}
