import { SalesOrderItem } from "../models/sales-order-item.model";
import { SalesOrderItemStateModel } from "../state/sales-order-item.state";

export namespace SalesOrderItemActions {
    export class Refresh {
        static readonly type = '[API-SalesOrderItem] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-SalesOrderItem] Init'
        constructor(public payload: SalesOrderItemStateModel) {}
    }
    export class Set {
        static readonly type = '[API-SalesOrderItem] Set'
        constructor(public payload: SalesOrderItem[]) {}
    }
}
