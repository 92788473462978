export class AssetField {
  id: number
  asset_category_id: number
  caption: string
  is_deleted: boolean
  
  constructor() {
  }

  static buildOne(obj: any): AssetField {
    let target: AssetField = Object.assign(new AssetField(), obj)
    return target
  }

  static buildMany(objs: any[]): AssetField[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(AssetField.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

