import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { JobType } from '../state-man/models/job-type.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class JobTypeService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getAll () : Promise<JobType[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'job_type/active/')           
      return (ret && Array.isArray(ret)) ? JobType.buildMany(ret) : null
    } 
    catch (error) {    
      LoggerService.log(error + 'job_type/active/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')      
      return null
    }
  }  
  
}
