export class Photo {
  // From backend
  id = 0
  photo_data = null
  photo_type = ''
  created_at:string = ''

  // Additional fields
  base64 = ''
  base64_sz = ''
  file_type = ''
  file_name = ''
  loaded = false
  uploaded = true
  uploading = false
  uuid = ''
  
  constructor() {
  }

  static async buildOne(obj: any): Promise<Photo> {
    return Object.assign(new Photo(), obj)
  }

  static async buildMany(objs: any): Promise<Photo[]> {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(await Photo.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

