import { Injectable } from '@angular/core'
import { Jsa } from '../models/jsa.model'
import { JsaActions } from '../actions/jsa.actions'
import { DateTime } from 'luxon';
import { Select, State, Action, StateContext, Selector, Store, NgxsOnInit, NgxsOnChanges, NgxsSimpleChange } from '@ngxs/store'
import { patch, append, removeItem, updateItem } from '@ngxs/store/operators';
import { JsaPhoto } from '../models/jsa-photo.model'
import { StorageService } from 'src/app/services/storage.service'
import { JsaService } from 'src/app/services/jsa.service'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { ConnectionStatusEnum, NetworkService } from 'src/app/services/network.service';
import { OfflineService } from 'src/app/services/offline.service';

export class JsaStateModel {
    is_refreshing: boolean
    pending_count: number
    last_update: string
    jsas: Jsa[]
}

@State<JsaStateModel>({
    name: 'jsa',
    defaults: {
        is_refreshing: true,
        pending_count: 0,
        last_update: '',
        jsas: []
    }
})
@Injectable()
export class JsaState implements NgxsOnInit, NgxsOnChanges {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(
        private storageService: StorageService,
        private store: Store,
        private jsaService: JsaService,
        private networkService: NetworkService,
        private offline: OfflineService
    ) {}

    async ngxsOnInit(ctx: StateContext<JsaStateModel>) {           
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                                                                                            
                        await this.reloadStateFromStorage()
                        this.store.dispatch(new JsaActions.IncrementPendingCount())
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {        
        if (this.storageService.is_initialized) {
            const state_model: JsaStateModel = change.currentValue 
            this.storageService.set('jsas', JSON.stringify(state_model))    
        }  
      
        if (this.networkService.currentStatus === ConnectionStatusEnum.Online) {                
            if (change.previousValue && change.previousValue.pending_count < change.currentValue.pending_count) {
                const state_model: JsaStateModel = change.currentValue 
                await this.offline.processPendingJsas(state_model.jsas) 
            }                                
        }        
    }

    async reloadStateFromStorage() {   
        const json = await this.storageService.get('jsas');        
        if (json) {
            const state_model: JsaStateModel = JSON.parse(json)
            if (state_model) {                                                               
                this.store.dispatch(new JsaActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new JsaActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {
        const jsaObjs = await this.jsaService.getScheduled()
        if (jsaObjs != null) {
          const jsas = Jsa.buildMany(jsaObjs)    
          const promises = []  
          for (const jsa of jsas) {
            promises.push(this.getAndSetPhotos(jsa))
          }
          await Promise.all(promises)
    
          this.store.dispatch(new JsaActions.Merge(jsas))
          return true
        } 
        return false        
    }

    async getAndSetPhotos(jsa: Jsa) {
        const photos = await this.jsaService.getPhotosByJsaId(jsa.id)
        if (photos) jsa.photos = await JsaPhoto.buildMany(photos)
    }

    @Selector()
    static getLastUpdate(state: JsaStateModel) {
        return state.last_update
    }

    @Selector()
    static getIsRefreshing(state: JsaStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getState(state: JsaStateModel) {
        return state
    }

    @Selector()
    static getJsas(state: JsaStateModel) {
        return state.jsas
    }

    @Selector()
    static getJsa(state: JsaStateModel) {
        return (id: number) => {
            return state.jsas.filter(s => s.id === id)
        }
    }

    @Action(JsaActions.Refresh)
    async refresh({patchState}: StateContext<JsaStateModel>, {  }:JsaActions.Refresh) {                  
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(JsaActions.IncrementPendingCount)
    incrementPendingCount({patchState, getState}: StateContext<JsaStateModel>, {  }:JsaActions.IncrementPendingCount) {     
        const state = getState()
        patchState({
            pending_count: state.pending_count + 1
        })
    }

    @Action(JsaActions.AddOne)
    addOne({getState, patchState}: StateContext<JsaStateModel>, { payload }:JsaActions.AddOne) {
        const state = getState()        
        patchState({
            jsas: [...state.jsas, payload]
        })
    }

    @Action(JsaActions.AddMany)
    addMany({getState, patchState}: StateContext<JsaStateModel>, { payload }:JsaActions.AddMany) {
        const state = getState()        
        patchState({
            jsas: [...state.jsas].concat(payload)
        })
    }

    @Action(JsaActions.Init)
    init({setState, getState}: StateContext<JsaStateModel>, { payload }:JsaActions.Init) {     
        const state = getState()
        payload.is_refreshing = state.is_refreshing        
        setState(payload)
        this.store.dispatch(new JsaActions.Refresh())
    }

    @Action(JsaActions.Set)
    set({setState, getState}: StateContext<JsaStateModel>, { payload }:JsaActions.Set) {  
        const state = getState()              
        setState({
            is_refreshing: state.is_refreshing,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            jsas: payload
        })
    }

    @Action(JsaActions.Merge)
    merge({getState, setState}: StateContext<JsaStateModel>, { payload }:JsaActions.Merge) {   
        // This is to merge data from the API provided in payload, into the app state.
        // Basically, if we have server data, we trust that, so we want to replace the 
        // current app state with the payload, except:
        // 1. Any images pending upload or delete need to be merged in, even if the JSA no longer
        //    exists.
        const state = getState()        
        const oldJsas = [...state.jsas]
        const newJsas = [...payload]

        // For each jsa in state now...
        for (const jsa of oldJsas) {   
            // If jsa id is not in the list from the server          
            if (!payload.find(f => f.id === jsa.id)) {
                // If there are any pending deletes or uploads, keep the entire jsa in state
                if (jsa.deleted_photos.length > 0 || jsa.photos.filter(f => f.uploaded === false).length > 0) {
                    newJsas.push(jsa)
                }
            } else {
                // Else, for the given jsa from the server that matches one in the state...
                let newJsa = payload.find(f => f.id === jsa.id)                
                if (newJsa) {
                    // Copy all deleted photos over, if any.  We will never have any from the server
                    newJsa.deleted_photos = jsa.deleted_photos

                    // Add in any photos pending upload
                    for (const photo of jsa.photos) {
                        if (!photo.uploaded) {
                            newJsa.photos.push(photo)
                        }
                    }
                }
            }
        }        

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            jsas: newJsas
        })
    }

    @Action(JsaActions.AddPhotoToJsa)
    addPhotoToJsa({ setState }: StateContext<JsaStateModel>, { payload }:JsaActions.AddPhotoToJsa) {         
        setState(
            patch<JsaStateModel>({
                jsas: updateItem((item: Jsa) => item.id === payload.jsa_id, patch({photos: append([payload.photo])}))
            })
        )
    }

    @Action(JsaActions.DeletePhotoFromJsa)
    deletePhotoFromJsa({ setState }: StateContext<JsaStateModel>, { payload }:JsaActions.DeletePhotoFromJsa) {         
        setState(
            patch<JsaStateModel>
            ({
                jsas: updateItem((item: Jsa) => item.id === payload.jsa_id, 
                    patch<Jsa>
                        ({
                            photos: removeItem(photo => photo.uuid === payload.photo.uuid),
                            deleted_photos: append([payload.photo])
                        })),
                last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")
            })
        )
    }

    @Action(JsaActions.ApiAddedPhotoToJsa)
    apiAddedPhotoToJsa({ setState }: StateContext<JsaStateModel>, { payload }:JsaActions.ApiAddedPhotoToJsa) {         
        setState(
            patch<JsaStateModel>
                ({
                    jsas: updateItem((item: Jsa) => item.id === payload.jsa_id, 
                        patch<Jsa>
                            ({
                                photos: updateItem((photo: JsaPhoto) => photo.uuid === payload.photo.uuid, 
                                    patch<JsaPhoto>({uploaded: true, id: payload.photo.id, photo_data: payload.photo.photo_data, 
                                        photo_type: payload.photo.photo_type, base64: payload.photo.base64,
                                        base64_sz: payload.photo.base64_sz
                                    })
                            )})),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")
                })
        )
    }

    @Action(JsaActions.ApiDeletedPhotoFromJsa)
    apiDeletedPhotoFromJsa({ setState }: StateContext<JsaStateModel>, { payload }:JsaActions.ApiDeletedPhotoFromJsa) {         
        setState(
            patch<JsaStateModel>({
                jsas: updateItem((item: Jsa) => item.id === payload.jsa_id, 
                patch({photos: removeItem(photo => photo.uuid === payload.photo.uuid),
                       deleted_photos: removeItem(dphoto => dphoto.uuid === payload.photo.uuid)}))
            })
        )
    }

    @Action(JsaActions.ApiStartUploading)
    apiStartUploading({ setState }: StateContext<JsaStateModel>, { payload }:JsaActions.ApiStartUploading) {         
        setState(
            patch<JsaStateModel>
                ({
                    jsas: updateItem((item: Jsa) => item.id === payload.jsa_id, 
                        patch<Jsa>
                            ({
                                photos: updateItem((photo: JsaPhoto) => photo.uuid === payload.photo.uuid, 
                                    patch<JsaPhoto>({uploading: true})
                            )})),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")
                })
        )
    }

    @Action(JsaActions.ApiStopUploading)
    apiStopUploading({ setState }: StateContext<JsaStateModel>, { payload }:JsaActions.ApiStopUploading) {         
        setState(
            patch<JsaStateModel>
                ({
                    jsas: updateItem((item: Jsa) => item.id === payload.jsa_id, 
                        patch<Jsa>
                            ({
                                photos: updateItem((photo: JsaPhoto) => photo.uuid === payload.photo.uuid, 
                                    patch<JsaPhoto>({uploading: false})
                            )})),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")
                })
        )
    }

}
