import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, Store } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { FormActions } from '../actions/form.actions';
import { Form } from '../models/form.model';
import { FormService } from 'src/app/services/form.service';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ConnectionStatusEnum, NetworkService } from 'src/app/services/network.service';
import { OfflineService } from 'src/app/services/offline.service';
import { DateTime } from 'luxon';
import { StorageService } from 'src/app/services/storage.service';

export class FormStateModel {
    is_refreshing: boolean
    pending_count: number
    last_update: string
    forms: Form[]
}

@State<FormStateModel>({
    name: 'form',
    defaults: {
        is_refreshing: true,
        pending_count: 0,
        last_update: '',
        forms: []
    }
})
@Injectable()
export class FormState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private formService: FormService,        
        private networkService: NetworkService,
        private offline: OfflineService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<FormStateModel>) {   
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                       
                        await this.reloadStateFromStorage()                        
                        this.store.dispatch(new FormActions.IncrementPendingCount())
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {        
        if (this.storageService.is_initialized) {
            const state_model: FormStateModel = change.currentValue
            this.storageService.set('forms', JSON.stringify(state_model))    
        }             
        if (this.networkService.currentStatus === ConnectionStatusEnum.Online) {     
            if (change.previousValue && change.previousValue.pending_count < change.currentValue.pending_count) {     
                const state_model: FormStateModel = change.currentValue   
                await this.offline.processPendingForms(state_model.forms)                
            }
        }     
    }

    async reloadStateFromStorage() {
        const json = await this.storageService.get('forms');
        if (json) {            
            const state_model: FormStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new FormActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new FormActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.formService.getMine()    
        if (objs != null) {
          const many = Form.buildMany(objs)                     
          this.store.dispatch(new FormActions.Merge(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getLastUpdate(state: FormStateModel) {
        return state.last_update
    }

    @Selector()
    static getIsRefreshing(state: FormStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getState(state: FormStateModel) {
        return state
    }

    @Selector()
    static getAll(state: FormStateModel) {
        return state.forms
    }

    @Selector()
    static getOne(state: FormStateModel) {
        return (id: number) => {
            return state.forms.filter(s => s.id === id)
        }
    }

    @Action(FormActions.Refresh)
    async refresh({patchState}: StateContext<FormStateModel>, {  }:FormActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(FormActions.IncrementPendingCount)
    incrementPendingCount({patchState, getState}: StateContext<FormStateModel>, {  }:FormActions.IncrementPendingCount) {     
        const state = getState()
        patchState({
            pending_count: state.pending_count + 1
        })
    }

    @Action(FormActions.Init)
    init({setState, getState}: StateContext<FormStateModel>, { payload }:FormActions.Init) {             
        const state = getState()
        payload.is_refreshing = state.is_refreshing

        const forms: Form[] = []
        for (const of of state.forms) {
            if (of.id <= 0) {
                // Only keep pending forms (id === 0) if the guid does not already 
                // exist with a non-zero id.
                const found = state.forms.find(f => f.id !== 0 && f.uuid === of.uuid)
                if (!found) {
                    // Only keep form if don't already have one with the same id/uuid
                    const found2 = forms.find(f => f.id === of.id && f.uuid === of.uuid)
                    if (!found2) forms.push(of) 
                }
            } else {
                // Only keep form if don't already have one with the same id/uuid
                const found2 = forms.find(f => f.id === of.id && f.uuid === of.uuid)
                if (!found2) forms.push(of) 
            }
        }

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            forms: forms
        })

        setState(payload)
        this.store.dispatch(new FormActions.Refresh())
    }
    
    @Action(FormActions.Set)
    set({setState, getState}: StateContext<FormStateModel>, { payload }:FormActions.Set) {     
        const state = getState()        

        setState({   
            is_refreshing: state.is_refreshing,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),         
            forms: payload
        })
    }

    @Action(FormActions.Merge)
    merge({getState, setState}: StateContext<FormStateModel>, { payload }:FormActions.Merge) {   
        // This is to merge data from the API provided in payload, into the app state.
        // Basically, if we have server data, we trust that, so we want to replace the 
        // current app state with the payload, except:
        // 1. Any objects pending upload or delete need to be merged in, even if it no longer exists.
        const state = getState()
        const oldFormsAll = [...state.forms]
        let newForms = [...payload]

        const oldForms: Form[] = []
        for (const of of oldFormsAll) {
            if (of.id <= 0) {
                // Only keep pending forms (id === 0) if the guid does not already 
                // exist with a non-zero id.
                const found = oldFormsAll.find(f => f.id !== 0 && f.uuid === of.uuid)
                if (!found) {
                    // Only keep form if don't already have one with the same id/uuid
                    const found2 = oldForms.find(f => f.id === of.id && f.uuid === of.uuid)
                    if (!found2) oldForms.push(of) 
                }
            } else {
                // Only keep form if don't already have one with the same id/uuid
                const found2 = oldForms.find(f => f.id === of.id && f.uuid === of.uuid)
                if (!found2) oldForms.push(of) 
            }
        }

        // For each form in state now...
        for (const form of oldForms) {   
            // If form id is not in the list from the server          
            if (!payload.find(f => f.id === form.id)) {
                // If there are any pending uploads, keep the entire object in state
                if (form.id <= 0) {
                    newForms.push(form)
                }
            } 
        }

        // For each form in state now...
        for (const form of oldForms) {   
            // If form id is not in the list from the server       
            let newForm = payload.find(f => f.id === form.id)   
            if (!newForm) {                
                // If there are any pending save/delete, keep the entire object in state, else do not since no longer on server                
                if (form.is_pending_save || form.is_pending_delete) {                    
                    newForms.push(form)
                }
            } else {                
                // Object in state is in the payload from the server. 
                // 1. If anything is pending, we need to preserve the one in state, first removing the one that is from the server
                // 1. Else we leave the one from the server to set in the new state
                if (form.is_pending_save || form.is_pending_delete) {             
                    // Remove one that is from the server
                    if (newForm) {
                        newForms = newForms.filter((obj) => {return obj.uuid !== newForm?.uuid});                        
                        // Add the one from state back in
                        newForms.push(form)
                    }
                }
            }
        }

        const newFormsAll = newForms
        newForms = []
        for (const of of newFormsAll) {
            if (of.id <= 0) {
                // Only keep pending forms (id === 0) if the guid does not already 
                // exist with a non-zero id.
                const found = newFormsAll.find(f => f.id !== 0 && f.uuid === of.uuid)
                if (!found) {
                    // Only keep form if don't already have one with the same id/uuid
                    const found2 = newForms.find(f => f.id === of.id && f.uuid === of.uuid)
                    if (!found2) newForms.push(of) 
                }
            } else {
                // Only keep form if don't already have one with the same id/uuid
                const found2 = newForms.find(f => f.id === of.id && f.uuid === of.uuid)
                if (!found2) newForms.push(of) 
            }
        }

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            forms: newForms
        })
    }

    @Action(FormActions.AddOne)
    addOne({getState, patchState, setState}: StateContext<FormStateModel>, { payload }:FormActions.AddOne) {
        const state = getState()              
        patchState({
            forms: [...state.forms, payload]
        })
    }

    @Action(FormActions.UpdateOne)
    updateOne({setState}: StateContext<FormStateModel>, { payload }:FormActions.UpdateOne) {
        setState(
            patch<FormStateModel>
                ({
                    forms: updateItem((item: Form) => item.id === payload.id, 
                        patch<Form>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(FormActions.DeleteOne)
    deleteOne({setState}: StateContext<FormStateModel>, { payload }:FormActions.DeleteOne) {
        setState(
            patch<FormStateModel>
                ({
                    forms: updateItem((item: Form) => item.id === payload.id, 
                        patch<Form>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(FormActions.ApiAddedOne)
    apiAddedOne({ setState }: StateContext<FormStateModel>, { payload }:FormActions.ApiAddedOne) {         
        setState(
            patch<FormStateModel>
                ({
                    forms: updateItem((item: Form) => item.uuid === payload.uuid, 
                        patch<Form>
                            ({
                                id: payload.id,                                
                                is_pending_save: false,
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(FormActions.ApiUpdatedOne)
    apiUpdatedOne({ setState }: StateContext<FormStateModel>, { payload }:FormActions.ApiUpdatedOne) {         
        setState(
            patch<FormStateModel>
                ({
                    forms: updateItem((item: Form) => item.id === payload.id, 
                        patch<Form>
                            ({
                                is_pending_save: false,
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(FormActions.ApiDeletedOne)
    apiDeletedOne({ setState }: StateContext<FormStateModel>, { payload }:FormActions.ApiDeletedOne) {         
        setState(
            patch<FormStateModel>
                ({
                    forms: removeItem((item: Form) => item.id === payload.id),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

}
