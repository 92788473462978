import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, Store } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { WorActions } from '../actions/wor.actions';
import { Wor } from '../models/wor.model';
import { WorService } from 'src/app/services/wor.service';
import { patch, updateItem, removeItem } from '@ngxs/store/operators';
import { ConnectionStatusEnum, NetworkService } from 'src/app/services/network.service';
import { OfflineService } from 'src/app/services/offline.service';
import { DateTime } from 'luxon';
import { StorageService } from 'src/app/services/storage.service';

export class WorStateModel {
    is_refreshing: boolean
    pending_count: number
    last_update: string
    wors: Wor[]
}

@State<WorStateModel>({
    name: 'wor',
    defaults: {
        is_refreshing: true,
        pending_count: 0,
        last_update: '',
        wors: []
    }
})
@Injectable()
export class WorState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private worService: WorService,        
        private networkService: NetworkService,
        private offline: OfflineService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<WorStateModel>) {   
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                        this.store.dispatch(new WorActions.IncrementPendingCount())
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {         
        if (this.storageService.is_initialized) {
            const state_model: WorStateModel = change.currentValue 
            this.storageService.set('wors', JSON.stringify(state_model))    
        }                         
        if (this.networkService.currentStatus === ConnectionStatusEnum.Online) {   
            if (change.previousValue && change.previousValue.pending_count < change.currentValue.pending_count) {     
                const state_model: WorStateModel = change.currentValue                     
                await this.offline.processPendingWors(state_model.wors)                        
            }
        }     
    }

    async reloadStateFromStorage() {
        const json: string = await this.storageService.get('wors');
        if (json) {
            const state_model: WorStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new WorActions.Init(state_model));
                return 
            }
        }
        this.store.dispatch(new WorActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.worService.getMine()    
        if (objs != null) {
          const many = Wor.buildMany(objs)                     
          this.store.dispatch(new WorActions.Merge(many))
          return true
        }
        return false
    }

    @Selector()
    static getLastUpdate(state: WorStateModel) {
        return state.last_update
    }

    @Selector()
    static getIsRefreshing(state: WorStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getState(state: WorStateModel) {
        return state
    }

    @Selector()
    static getAll(state: WorStateModel) {
        return state.wors
    }

    @Selector()
    static getOne(state: WorStateModel) {
        return (id: number) => {
            return state.wors.filter(s => s.id === id)
        }
    }

    @Action(WorActions.Refresh)
    async refresh({patchState}: StateContext<WorStateModel>, {  }:WorActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(WorActions.IncrementPendingCount)
    incrementPendingCount({patchState, getState}: StateContext<WorStateModel>, {  }:WorActions.IncrementPendingCount) {     
        const state = getState()
        patchState({
            pending_count: state.pending_count + 1
        })
    }

    @Action(WorActions.Init)
    init({setState, getState}: StateContext<WorStateModel>, { payload }:WorActions.Init) {             
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new WorActions.Refresh())
    }

    @Action(WorActions.Set)
    set({setState, getState}: StateContext<WorStateModel>, { payload }:WorActions.Set) {        
        const state = getState()        
        setState({   
            is_refreshing: state.is_refreshing,  
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),         
            wors: payload
        })
    }

    @Action(WorActions.Merge)
    merge({getState, setState}: StateContext<WorStateModel>, { payload }:WorActions.Merge) {   
        // This is to merge data from the API provided in payload, into the app state.
        // Basically, if we have server data, we trust that, so we want to replace the 
        // current app state with the payload, except:
        // 1. Any objects pending upload or delete need to be merged in, even if it no longer exists.
        const state = getState()
        const oldWors = [...state.wors]
        let newWors = [...payload]        

        // For each wor in state now...
        for (const wor of oldWors) {   
            // If wor id is not in the list from the server       
            let newWor = payload.find(f => f.id === wor.id)   
            if (!newWor) {                
                // If there are any pending save/delete, keep the entire object in state, else do not since no longer on server                
                if (wor.is_pending_save || wor.is_pending_delete) {                    
                    newWors.push(wor)
                }
            } else {                
                // Object in state is in the payload from the server. 
                // 1. If anything is pending, we need to preserve the one in state, first removing the one that is from the server
                // 1. Else we leave the one from the server to set in the new state
                if (wor.is_pending_save || wor.is_pending_delete) {
                    // Remove one that is from the server
                    newWors = newWors.filter((obj) => {return obj !== newWor});                        
                    // Add the one from state back in
                    newWors.push(wor)
                }
            }
        }

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            wors: newWors
        })
    }

    @Action(WorActions.AddOne)
    addOne({getState, patchState}: StateContext<WorStateModel>, { payload }:WorActions.AddOne) {
        const state = getState()        
        patchState({
            wors: [...state.wors, payload]
        })
    }

    @Action(WorActions.UpdateOne)
    updateOne({setState}: StateContext<WorStateModel>, { payload }:WorActions.UpdateOne) {
        setState(
            patch<WorStateModel>
                ({
                    wors: updateItem((item: Wor) => item.id === payload.id, 
                        patch<Wor>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(WorActions.DeleteOne)
    deleteOne({setState}: StateContext<WorStateModel>, { payload }:WorActions.DeleteOne) {
        setState(
            patch<WorStateModel>
                ({
                    wors: updateItem((item: Wor) => item.id === payload.id, 
                        patch<Wor>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(WorActions.ApiAddedOne)
    apiAddedOne({ setState }: StateContext<WorStateModel>, { payload }:WorActions.ApiAddedOne) {         
        setState(
            patch<WorStateModel>
                ({
                    wors: updateItem((item: Wor) => item.uuid === payload.uuid, 
                        patch<Wor>
                            ({
                                id: payload.id,
                                is_pending_save: false,
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(WorActions.ApiUpdatedOne)
    apiUpdatedOne({ setState }: StateContext<WorStateModel>, { payload }:WorActions.ApiUpdatedOne) {         
        setState(
            patch<WorStateModel>
                ({
                    wors: updateItem((item: Wor) => item.id === payload.id, 
                        patch<Wor>
                            ({
                                is_pending_save: false,
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(WorActions.ApiDeletedOne)
    apiDeletedOne({ setState }: StateContext<WorStateModel>, { payload }:WorActions.ApiDeletedOne) {         
        setState(
            patch<WorStateModel>
                ({
                    wors: removeItem((item: Wor) => item.id === payload.id),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

}
