import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import * as myGlobals from '../globals';
import { LoggerService } from './logger.service';
import { create } from 'apisauce';
import { DateTime } from 'luxon';
import { Select } from '@ngxs/store'
import { UserState, UserStateModel } from 'src/app/state-man/state/user.state';
import { Observable } from 'rxjs';
import { User } from 'src/app/state-man/models/user.model';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { ApiError } from 'src/app/utils/api-error';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  @Select(UserState.getUser) user$: Observable<User>
  user: User
  url: string
  timeout
  apiClient
  prev_urls: string[] = []
  current_url: string = ""
  skip_next_push: boolean = false

  constructor(     
    private router: Router
  ) {
    this.url = this.getBaseApiUrl();
    this.timeout = myGlobals.app_api_timeout_ms;
    this.apiClient = create({
      baseURL: this.url,
      timeout: this.timeout
    });
    this.user = UserStateModel.getDefaultUser()
    this.user$.subscribe((val: User) => {                
      this.user = val
    })

    this.router.events.subscribe(event => {
      if (event.type === 1) {
        const ne: NavigationEnd = event as NavigationEnd
        if (this.skip_next_push) {
          this.skip_next_push = false
        } else {
          this.prev_urls.push(this.current_url)
        }
        this.current_url = ne.url
      }
    })
  }

  // checks if is object, null val returns false
  static isObject(val: any) {
    if (val === null) { return false; }
    return ( (typeof val === 'function') || (typeof val === 'object') );
  }

  static getRetDataFromApiResults(api_results: any) {
    const resultObj: any = api_results;
    if (resultObj == null) {
      return null;
    } else {
      return resultObj;
    }
  }

  static sortByReverseDueDate(aObj: any, bObj: any) {
    const a = DateTime.fromISO(bObj.due_date,  { zone: "utc" });
    const b = DateTime.fromISO(aObj.due_date,  { zone: "utc" });
    return ((a < b) ? -1 : ((a > b) ? 1 : 0));
  }

  static sortByReverseCreatedDate(aObj: any, bObj: any) {
    let a
    let b
    if (aObj.created) {
      a = DateTime.fromISO(bObj.created,  { zone: "utc" });
      b = DateTime.fromISO(aObj.created,  { zone: "utc" });
    } else if (aObj.created_at) {
      a = DateTime.fromISO(bObj.created_at,  { zone: "utc" });
      b = DateTime.fromISO(aObj.created_at,  { zone: "utc" });
    } else if (aObj.create_date) {
      a = DateTime.fromISO(bObj.create_date,  { zone: "utc" });
      b = DateTime.fromISO(aObj.create_date,  { zone: "utc" });
    }
    
    if (a && b) {
      return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    }

    return 0
  }

  static sortByReverseDateWorked(aObj: any, bObj: any) {
    let a
    let b
    if (aObj.date_worked) {
      a = DateTime.fromISO(bObj.date_worked,  { zone: "utc" });
      b = DateTime.fromISO(aObj.date_worked,  { zone: "utc" });
    } 
    
    if (a && b) {
      return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    }

    return 0
  }

  static sortByDateWorkedShort(aObj: any, bObj: any) {
    let a
    let b
    let format: string = 'M/d/yyyy'
    if (aObj.date_worked) {
      a = DateTime.fromFormat(aObj.date_worked, format);
      b = DateTime.fromFormat(bObj.date_worked, format);
    } 
    
    if (a && b) {
      return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    }

    return 0
  }

  static sortByWorkDateShort(aObj: any, bObj: any) {
    let a
    let b
    let format: string = 'yyyy-MM-dd'
    if (aObj.work_date) {
      a = DateTime.fromFormat(aObj.work_date, format);
      b = DateTime.fromFormat(bObj.work_date, format);
    } 
    
    if (a && b) {
      return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    }

    return 0
  }

  static sortByWorkDateIso(aObj: any, bObj: any) {
    let a
    let b
    let format: string = 'yyyy-MM-dd'
    if (aObj.work_date) {
      a = DateTime.fromISO(aObj.work_date);
      b = DateTime.fromISO(bObj.work_date);
    } 
    
    if (a && b) {
      return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    }

    return 0
  }

  static sortByReverseUpdatedDate(aObj: any, bObj: any) {
    const a = DateTime.fromFormat(bObj.updated_at, 'yyyy-MM-dd h:mm:ss a');
    const b = DateTime.fromFormat(aObj.updated_at, 'yyyy-MM-dd h:mm:ss a');
    return ((a < b) ? -1 : ((a > b) ? 1 : 0));
  }

  static sortByUpdatedDate(aObj: any, bObj: any) {
    const a = DateTime.fromFormat(aObj.updated_at, 'yyyy-MM-dd h:mm:ss a');
    const b = DateTime.fromFormat(bObj.updated_at, 'yyyy-MM-dd h:mm:ss a');
    return ((a < b) ? -1 : ((a > b) ? 1 : 0));
  }

  getBaseApiUrl() {
    return environment.baseApiUrl
  }

  getBaseAppUrl() {
    return window.location.origin + '/';
  }

  async isAccessible() {
    const results = await this.apiReturnsAny('get', 'app_settings');
    return results ? true : false;
  }

  async isAccessibleOrThrow() {
    if (!this.isAccessible()) {
      throw new Error('Server is not accessible');
    }
  }
  
  private async getRequestConfig(method: string, url: string, params: any, content_type: string)
  {    
    let token: string = this.user.token   
    if (!token) token = '';

    const headers = {
      'Content-Type' : content_type, 
      'Authorization' : 'Bearer ' + token,
      'RT2-App-Version' : myGlobals.app_version,
    }

    if (method === 'post' && url != 'auth/login') {
      return {method, url, data: JSON.stringify(params), headers}
    } else if (method === 'put') {
      return {method, url, data: JSON.stringify(params), headers}
    } else if (method === 'delete') {
      return {method, url, data: JSON.stringify(params), headers}
    } else {
      return {method, url, params, headers}
    }
  }

  private async apiReturnsData(method: string, url: string, params: any, content_type: string, configAdditions?: any) {
    let response: any;

    const defaultConfig: any = await this.getRequestConfig(method, url, params, content_type);
    const config = {...defaultConfig, ...configAdditions};
    // Hack to force use of post so we get the automatic formatting of multipart form data.  We don't get that if using any for some reason.
    if (method === 'post' && content_type == 'multipart/form-data') {
      response = await this.apiClient.post(url, params, config)
    } else if (method === 'put') {
      response = await this.apiClient.put(url, params, config)
    } else if (method === 'delete') {
      response = await this.apiClient.delete(url, {}, config)
    } else {
      response = await this.apiClient.any(config)
    }

    if (response.ok && response.data) {
      return response.data
    } else {
      let msg = 'API error (will rethrow)';
      if (response && response.problem) {
        msg += ': ' + response.problem
      }
      //LoggerService.log(response)     
      if (response.status != '401') {
        throw new ApiError('NETWORK_ERROR', response.data)
      } else {
        throw new ApiError('UNAUTHORIZED_ERROR', response.data)
      }      
    }
  }

  public async apiReturnsInt(method: string, url: string, params={}, content_type='application/x-www-form-urlencoded') : Promise<number> {
    const retData = await this.apiReturnsData(method, url, params, content_type);   
    if (retData) {
      const parsed = parseInt(retData, 10) 
      parsed ? parsed : 0
    }
    return 0    
  }

  public async apiReturnsAny(method: string, url: string, params={}, content_type='application/x-www-form-urlencoded', configAdditions?: any) : Promise<any | null> {
    const retData = await this.apiReturnsData(method, url, params, content_type, configAdditions);
    return retData ? retData : null;
  }

  goBack(params: string="", num: number=1) {
    this.skip_next_push = true
    let pop = this.prev_urls.pop()
    num--
    while (num > 0) {
      pop = this.prev_urls.pop()
      num--
    }
    if (pop !== '') {
      if (params && params.length > 0) {        
        // Remove any previous params first.
        pop = pop?.substring(0, pop.lastIndexOf('/'))        
        this.router.navigate([pop, params]);
      } else {
        this.router.navigate([pop]);
      }
    } else {
      this.router.navigate(["/tabs/"]);
    }
  }

  public async goHome() {
    location.href = this.getBaseAppUrl();
  }
}


