import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, Store } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { MrfActions } from '../actions/mrf.actions';
import { Mrf } from '../models/mrf.model';
import { MrfService } from 'src/app/services/mrf.service';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ConnectionStatusEnum, NetworkService } from 'src/app/services/network.service';
import { OfflineService } from 'src/app/services/offline.service';
import { DateTime } from 'luxon';
import { StorageService } from 'src/app/services/storage.service';

export class MrfStateModel {
    is_refreshing: boolean
    pending_count: number
    last_update: string
    mrfs: Mrf[]
}

@State<MrfStateModel>({
    name: 'mrf',
    defaults: {
        is_refreshing: true,
        pending_count: 0,
        last_update: '',
        mrfs: []
    }
})
@Injectable()
export class MrfState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private mrfService: MrfService,        
        private networkService: NetworkService,
        private offline: OfflineService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<MrfStateModel>) {   
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                        this.store.dispatch(new MrfActions.IncrementPendingCount())
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {               
        if (this.storageService.is_initialized) {
            const state_model: MrfStateModel = change.currentValue  
            this.storageService.set('mrfs', JSON.stringify(state_model))    
        }  
        if (this.networkService.currentStatus === ConnectionStatusEnum.Online) {     
            if (change.previousValue && change.previousValue.pending_count < change.currentValue.pending_count) {    
                const state_model: MrfStateModel = change.currentValue                     
                await this.offline.processPendingMrfs(state_model.mrfs)                        
            }
        }       
    }

    async reloadStateFromStorage() {
        const json: string = await this.storageService.get('mrfs');
        if (json) {
            const state_model: MrfStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new MrfActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new MrfActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.mrfService.getMine()    
        if (objs != null) {
          const many = Mrf.buildMany(objs)                     
          this.store.dispatch(new MrfActions.Merge(many))
          return true
        }
        return false
    }

    @Selector()
    static getLastUpdate(state: MrfStateModel) {
        return state.last_update
    }

    @Selector()
    static getIsRefreshing(state: MrfStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getState(state: MrfStateModel) {
        return state
    }

    @Selector()
    static getAll(state: MrfStateModel) {
        return state.mrfs
    }

    @Selector()
    static getOne(state: MrfStateModel) {
        return (id: number) => {
            return state.mrfs.filter(s => s.id === id)
        }
    }

    @Action(MrfActions.Refresh)
    async refresh({patchState}: StateContext<MrfStateModel>, {  }:MrfActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(MrfActions.IncrementPendingCount)
    incrementPendingCount({patchState, getState}: StateContext<MrfStateModel>, {  }:MrfActions.IncrementPendingCount) {     
        const state = getState()
        patchState({
            pending_count: state.pending_count + 1
        })
    }
    
    @Action(MrfActions.Init)
    init({setState, getState}: StateContext<MrfStateModel>, { payload }:MrfActions.Init) {             
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new MrfActions.Refresh())
    }

    @Action(MrfActions.Set)
    set({setState, getState}: StateContext<MrfStateModel>, { payload }:MrfActions.Set) {  
        const state = getState()        
        setState({   
            is_refreshing: state.is_refreshing,  
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),         
            mrfs: payload
        })
    }

    @Action(MrfActions.Merge)
    merge({getState, setState}: StateContext<MrfStateModel>, { payload }:MrfActions.Merge) {   
        // This is to merge data from the API provided in payload, into the app state.
        // Basically, if we have server data, we trust that, so we want to replace the 
        // current app state with the payload, except:
        // 1. Any objects pending upload or delete need to be merged in, even if it no longer exists.
        const state = getState()
        const oldMrfs = [...state.mrfs]
        let newMrfs = [...payload]

        // For each wor in state now...
        for (const mrf of oldMrfs) {   
            // If wor id is not in the list from the server       
            let newMrf = payload.find(f => f.id === mrf.id)   
            if (!newMrf) {                
                // If there are any pending save/delete, keep the entire object in state, else do not since no longer on server                
                if (mrf.is_pending_save || mrf.is_pending_delete) {                    
                    newMrfs.push(mrf)
                }
            } else {                
                // Object in state is in the payload from the server. 
                // 1. If anything is pending, we need to preserve the one in state, first removing the one that is from the server
                // 1. Else we leave the one from the server to set in the new state
                if (mrf.is_pending_save || mrf.is_pending_delete) {
                    // Remove one that is from the server
                    newMrfs = newMrfs.filter((obj) => {return obj !== newMrf});                        
                    // Add the one from state back in
                    newMrfs.push(mrf)
                }
            }
        }

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            mrfs: newMrfs
        })
    }

    @Action(MrfActions.AddOne)
    addOne({getState, patchState}: StateContext<MrfStateModel>, { payload }:MrfActions.AddOne) {
        const state = getState()        
        patchState({
            mrfs: [...state.mrfs, payload]
        })
    }

    @Action(MrfActions.UpdateOne)
    updateOne({setState}: StateContext<MrfStateModel>, { payload }:MrfActions.UpdateOne) {
        setState(
            patch<MrfStateModel>
                ({
                    mrfs: updateItem((item: Mrf) => item.id === payload.id, 
                        patch<Mrf>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(MrfActions.DeleteOne)
    deleteOne({setState}: StateContext<MrfStateModel>, { payload }:MrfActions.DeleteOne) {
        setState(
            patch<MrfStateModel>
                ({
                    mrfs: updateItem((item: Mrf) => item.id === payload.id, 
                        patch<Mrf>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(MrfActions.ApiAddedOne)
    apiAddedOne({ setState }: StateContext<MrfStateModel>, { payload }:MrfActions.ApiAddedOne) {         
        setState(
            patch<MrfStateModel>
                ({
                    mrfs: updateItem((item: Mrf) => item.uuid === payload.uuid, 
                        patch<Mrf>
                            ({
                                id: payload.id,
                                is_pending_save: false,
                                mrf_line_items: payload.mrf_line_items
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(MrfActions.ApiUpdatedOne)
    apiUpdatedOne({ setState }: StateContext<MrfStateModel>, { payload }:MrfActions.ApiUpdatedOne) {         
        setState(
            patch<MrfStateModel>
                ({
                    mrfs: updateItem((item: Mrf) => item.id === payload.id, 
                        patch<Mrf>
                            ({
                                is_pending_save: false,
                                mrf_line_items: payload.mrf_line_items
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(MrfActions.ApiDeletedOne)
    apiDeletedOne({ setState }: StateContext<MrfStateModel>, { payload }:MrfActions.ApiDeletedOne) {         
        setState(
            patch<MrfStateModel>
                ({
                    mrfs: removeItem((item: Mrf) => item.id === payload.id),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }
}