import { Contact } from "../models/contact.model";
import { ContactStateModel } from "../state/contact.state";

export namespace ContactActions {
    export class Refresh {
        static readonly type = '[API-Contact] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Contact] Init'
        constructor(public payload: ContactStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Contact] Set'
        constructor(public payload: Contact[]) {}
    }
}
