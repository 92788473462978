import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { SalesOrderItemActions } from '../actions/sales-order-item.actions';
import { SalesOrderItem } from '../models/sales-order-item.model';
import { SalesOrderItemService } from 'src/app/services/sales-order-item.service';
import { StorageService } from 'src/app/services/storage.service';

export class SalesOrderItemStateModel {
    is_refreshing: boolean
    sales_order_item: SalesOrderItem[]
}

@State<SalesOrderItemStateModel>({
    name: 'sales_order_item',
    defaults: {
        is_refreshing: true,
        sales_order_item: []
    }
})
@Injectable()
export class SalesOrderItemState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private salesOrderItemService: SalesOrderItemService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<SalesOrderItemStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {         
        if (this.storageService.is_initialized) {
            const state_model: SalesOrderItemStateModel = change.currentValue
            this.storageService.set('sales_order_items', JSON.stringify(state_model))    
        }     
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('sales_order_items');        
        if (json) {
            const state_model: SalesOrderItemStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new SalesOrderItemActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new SalesOrderItemActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.salesOrderItemService.getAll()    
        if (objs != null) {
          const many = SalesOrderItem.buildMany(objs)                  
          this.store.dispatch(new SalesOrderItemActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: SalesOrderItemStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: SalesOrderItemStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: SalesOrderItemStateModel) {
        return state.sales_order_item
    }

    @Action(SalesOrderItemActions.Refresh)
    async refresh({patchState}: StateContext<SalesOrderItemStateModel>, {  }:SalesOrderItemActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(SalesOrderItemActions.Init)
    init({setState, getState}: StateContext<SalesOrderItemStateModel>, { payload }:SalesOrderItemActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new SalesOrderItemActions.Refresh())
    }
    
    @Action(SalesOrderItemActions.Set)
    set({setState}: StateContext<SalesOrderItemStateModel>, { payload }:SalesOrderItemActions.Set) {             
        setState({  
            is_refreshing: false,          
            sales_order_item: payload
        })
    }
}
