import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AssetField } from '../state-man/models/asset-field.model';

@Injectable({
  providedIn: 'root'
})
export class AssetFieldService {

  constructor(
    private apiService: ApiService
  ) { }
  
  public async getAll () : Promise<AssetField[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'assets/fields/')         
      return (ret && Array.isArray(ret)) ? AssetField.buildMany(ret) : null
    } 
    catch (error) {      
      return null
    }
  }  
}
