import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AssetCategory } from '../state-man/models/asset-category.model';

@Injectable({
  providedIn: 'root'
})
export class AssetCategoryService {

  constructor(
    private apiService: ApiService
  ) { }
  
  public async getAll () : Promise<AssetCategory[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'assets/categories/')         
      return (ret && Array.isArray(ret)) ? AssetCategory.buildMany(ret) : null
    } 
    catch (error) {      
      return null
    }
  }  
}
