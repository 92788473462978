export class AssetCategory {
  id: number
  name: string
  sort_order: number
  is_deleted: boolean
  
  constructor() {
  }

  static buildOne(obj: any): AssetCategory {
    let target: AssetCategory = Object.assign(new AssetCategory(), obj)
    return target
  }

  static buildMany(objs: any[]): AssetCategory[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(AssetCategory.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

