import { Area } from "../models/area.model";
import { AreaStateModel } from "../state/area.state";

export namespace AreaActions {
    export class Refresh {
        static readonly type = '[API-Area] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Area] Init'
        constructor(public payload: AreaStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Area] Set'
        constructor(public payload: Area[]) {}
    }
}
