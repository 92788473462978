export class Asset {
  id: number
  site_id: number
  asset_category_id: number
  asset_field_id: number
  caption: string
  data: string
  
  constructor() {
  }

  static buildOne(obj: any): Asset {
    let target: Asset = Object.assign(new Asset(), obj)
    return target
  }

  static buildMany(objs: any[]): Asset[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Asset.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

