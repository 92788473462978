export class AppConfig {
  state_version: string  
  
  constructor() {    
  }

  static buildOne(obj: any): AppConfig {
    let target: AppConfig = Object.assign(new AppConfig(), obj)
    return target
  }

  static buildMany(objs: any[]): AppConfig[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(AppConfig.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

