import { WorkSchedule } from "../models/work-schedule.model";
import { WorkScheduleStateModel } from "../state/work-schedule.state";

export namespace WorkScheduleActions {
    export class Refresh {
        static readonly type = '[API-WorkSchedule] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-WorkSchedule] Init'
        constructor(public payload: WorkScheduleStateModel) {}
    }
    export class Set {
        static readonly type = '[API-WorkSchedule] Set'
        constructor(public payload: WorkSchedule[]) {}
    }
}
