import { v4 as uuid } from 'uuid'

export class ServiceEntry {
  uuid: string
  id: number  
  dsr_id: number
  service_entry_id: number
  service_detail_id: number
  start_time: string
  end_time: string
  mileage: number  
  technician_id: number
  technician: string
  regular_hours: string
  ot_hours: string
  total_hours: string
  vehicle_name: string
  regular_service_type_name: string
  time: string
  time_approval_status_id: number
  is_approval_pending: boolean
  employee_approval_date: string
  tz: string
  
  constructor() {
  }

  public setViewModelFields()
  {
    if (this.id === undefined) {
      this.id = 0
    }
    if (this.uuid === undefined) {
      this.uuid = uuid();
    }
    if (this.is_approval_pending === undefined) {
      this.is_approval_pending = false;
    }
    if (this.time_approval_status_id === undefined) {
      this.time_approval_status_id = 0;
    }
  }

  static buildOne(obj: any): ServiceEntry {
    let target: ServiceEntry = Object.assign(new ServiceEntry(), obj)  
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): ServiceEntry[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(ServiceEntry.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

