import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { AreaActions } from '../actions/area.actions';
import { Area } from '../models/area.model';
import { AreaService } from 'src/app/services/area.service';
import { StorageService } from 'src/app/services/storage.service';

export class AreaStateModel {
    is_refreshing: boolean
    area: Area[]
}

@State<AreaStateModel>({
    name: 'area',
    defaults: {
        is_refreshing: true,
        area: []
    }
})
@Injectable()
export class AreaState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private areaService: AreaService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<AreaStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                        
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {           
        if (this.storageService.is_initialized) {   
            const state_model: AreaStateModel = change.currentValue         
            this.storageService.set('areas', JSON.stringify(state_model))  
        }    
    }

    async reloadStateFromStorage() {        
        const json: string = await this.storageService.get('areas');        
        if (json) {
            const state_model: AreaStateModel = JSON.parse(json)             
            if (state_model) {                
                this.store.dispatch(new AreaActions.Init(state_model));
                return
            } 
        }
        this.store.dispatch(new AreaActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.areaService.getAll()            
        if (objs != null) {
          const many = Area.buildMany(objs)               
          this.store.dispatch(new AreaActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: AreaStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: AreaStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: AreaStateModel) {
        return state.area
    }

    @Action(AreaActions.Refresh)
    async refresh({patchState}: StateContext<AreaStateModel>, {  }:AreaActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }
    
    @Action(AreaActions.Init)
    init({setState, getState}: StateContext<AreaStateModel>, { payload }:AreaActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new AreaActions.Refresh())
    }

    @Action(AreaActions.Set)
    set({setState}: StateContext<AreaStateModel>, { payload }:AreaActions.Set) {             
        setState({    
            is_refreshing: false,        
            area: payload
        })
    }
}
