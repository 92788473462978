import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Customer } from '../state-man/models/customer.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }
  
  public async getAll () : Promise<Customer[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'customer/active/')         
      return (ret && Array.isArray(ret)) ? Customer.buildMany(ret) : null
    } 
    catch (error) {     
      LoggerService.log(error + 'customer/active')    
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')   
      return null
    }
  }  

}
