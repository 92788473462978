import { Injectable } from '@angular/core'
import { State, Action, StateContext, Selector } from '@ngxs/store'
import { AppActions } from '../actions/app.actions';
import { App } from '../models/app.model';

export class AppStateModel {
    app: App
}

@State<AppStateModel>({
    name: 'app',
    defaults: {       
        app: App.buildOne({is_refreshing: false})
    }
})
@Injectable()
export class AppState {
    constructor(                
    ) {}

    @Selector()
    static getApp(state: AppStateModel) {
        return state.app
    }

    @Selector()
    static getState(state: AppStateModel) {
        return state
    }
    
    @Action(AppActions.Set)
    set({setState}: StateContext<AppStateModel>, { payload }:AppActions.Set) {             
        setState({                     
            app: payload
        })
    }
}
