import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { WorkSchedule } from '../state-man/models/work-schedule.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class WorkScheduleService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getScheduled() : Promise<WorkSchedule[] | null> {   
    try {
      const work_schedules: WorkSchedule[] = []
      const ret = await this.apiService.apiReturnsAny('get', 'work_schedule/tech_schedule/')  
      return (ret && Array.isArray(ret) && ret[0] && ret[0].records && Array.isArray(ret[0].records)) ? WorkSchedule.buildMany(ret[0].records) : null
    } 
    catch (error) {
      LoggerService.log(error + 'work_schedule/tech_schedule')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }    
}
