import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { Pto } from '../models/pto.model';
import { PtoService } from 'src/app/services/pto.service';
import { PtoActions } from '../actions/pto.actions';

export class PtoStateModel {
    is_refreshing: boolean
    pto: Pto
    static getDefault(): Pto {
        return {     
            sick_hours_given_ytd: 0,
            sick_hours_used_ytd: 0,            
            vacation_hours_offer: 0,
            vacation_hours_carry_over: 0,
            vacation_hours_given_ytd: 0,
            vacation_hours_used_ytd: 0            
        }
    }
}

@State<PtoStateModel>({
    name: 'pto',
    defaults: {
        is_refreshing: true,
        pto: PtoStateModel.getDefault()
    }
})
@Injectable()
export class PtoState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>
    user: User

    constructor(        
        private ptoService: PtoService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<PtoStateModel>) {                
        this.user$.subscribe(async (user: User) => {        
            this.user = user    
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {       
        if (this.storageService.is_initialized) {
            const state_model: PtoStateModel = change.currentValue
            this.storageService.set('pto', JSON.stringify(state_model))    
        }      
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('pto');        
        if (json) {
            const state_model: PtoStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new PtoActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new PtoActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const obj = await this.ptoService.getByUserId(this.user.id)            
        if (obj != null) {
          const one = Pto.buildOne(obj)                  
          this.store.dispatch(new PtoActions.Set(one))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: PtoStateModel) {
        return state
    }

    @Selector()
    static get(state: PtoStateModel) {
        return state.pto
    }

    @Selector()
    static getIsRefreshing(state: PtoStateModel) {
        return state.is_refreshing
    }

    @Action(PtoActions.Refresh)
    async refresh({patchState}: StateContext<PtoStateModel>, {  }:PtoActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(PtoActions.Init)
    init({setState, getState}: StateContext<PtoStateModel>, { payload }:PtoActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new PtoActions.Refresh())
    }
    
    @Action(PtoActions.Set)
    set({setState, getState}: StateContext<PtoStateModel>, { payload }:PtoActions.Set) {             
        const state = getState()  
        setState({   
            is_refreshing: false,          
            pto: payload
        })
    }
}
