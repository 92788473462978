import { Jsa, JsaAndPhoto } from "../models/jsa.model";
import { JsaStateModel } from "../state/jsa.state";

export namespace JsaActions {
    export class Refresh {
        static readonly type = '[API-JSA] Refresh'
        constructor() {}
    }
    export class IncrementPendingCount {
        static readonly type = '[Network Service-JSA] Increment Pending Count'
        constructor() {}
    }    
    export class AddOne {
        static readonly type = '[API-JSA] Add One'
        constructor(public payload: Jsa) {}
    }
    export class AddMany {
        static readonly type = '[API-JSA] Add Many'
        constructor(public payload: Jsa[]) {}
    }
    export class Init {
        static readonly type = '[API-JSA] Init'
        constructor(public payload: JsaStateModel) {}
    }
    export class Set {
        static readonly type = '[API-JSA] Set'
        constructor(public payload: Jsa[]) {}
    }
    export class Merge {
        static readonly type = '[API-JSA] Merge'
        constructor(public payload: Jsa[]) {}
    }
    export class AddPhotoToJsa {
        static readonly type = '[UI-JSA] Add Photo to JSA'
        constructor(public payload: JsaAndPhoto) {}
    }
    export class DeletePhotoFromJsa {
        static readonly type = '[UI-JSA] Delete Photo from JSA'
        constructor(public payload: JsaAndPhoto) {}
    }

    export class ApiAddedPhotoToJsa {
        static readonly type = '[API-JSA] Added Photo to JSA'
        constructor(public payload: JsaAndPhoto) {}
    }
    export class ApiDeletedPhotoFromJsa {
        static readonly type = '[API-JSA] Deleted Photo from JSA'
        constructor(public payload: JsaAndPhoto) {}
    }
    export class ApiStartUploading {
        static readonly type = '[API-JSA] Start Uploading Photo'
        constructor(public payload: JsaAndPhoto) {}
    }
    export class ApiStopUploading {
        static readonly type = '[API-JSA] Stop Uploading Photo'
        constructor(public payload: JsaAndPhoto) {}
    }
}
