import { Vehicle } from "../models/vehicle.model";
import { VehicleStateModel } from "../state/vehicle.state";

export namespace VehicleActions {
    export class Refresh {
        static readonly type = '[API-Vehicle] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Vehicle] Init'
        constructor(public payload: VehicleStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Vehicle] Set'
        constructor(public payload: Vehicle[]) {}
    }
}
