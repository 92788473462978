import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceEntryService {

  constructor(
    private apiService: ApiService
  ) { }

  public async approve (ids: number[], employee_approval_date: string) {        
    const body = { ids, employee_approval_date }
    return await this.apiService.apiReturnsAny('post', 'service_entry/tech_approve/', body, 'application/json')      
  }  
  
}
