import { Injectable } from '@angular/core'
import { DailyWork } from '../models/daily-work.model'
import { DailyWorkActions } from '../actions/daily-work.actions'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { DailyWorkService } from 'src/app/services/daily-work.service'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { StorageService } from 'src/app/services/storage.service'

export class DailyWorkStateModel {
    is_refreshing: boolean
    daily_work: DailyWork[]
}

@State<DailyWorkStateModel>({
    name: 'daily_work',
    defaults: {
        is_refreshing: true,
        daily_work: []
    }
})
@Injectable()
export class DailyWorkState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private dailyWorkService: DailyWorkService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<DailyWorkStateModel>) {      
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                     
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {           
        if (this.storageService.is_initialized) {
            const state_model: DailyWorkStateModel = change.currentValue
            this.storageService.set('daily_work', JSON.stringify(state_model))    
        }  
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('daily_work');        
        if (json) {
            const state_model: DailyWorkStateModel = JSON.parse(json)            
            if (state_model) {                
                this.store.dispatch(new DailyWorkActions.Init(state_model));
                return
            } 
        }
        this.store.dispatch(new DailyWorkActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.dailyWorkService.getScheduled()    
        if (objs != null) {
          const daily_work = DailyWork.buildMany(objs)                  
          this.store.dispatch(new DailyWorkActions.Set(daily_work))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: DailyWorkStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: DailyWorkStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getScheduledAll(state: DailyWorkStateModel) {
        return state.daily_work
    }

    @Selector()
    static getScheduledToday(state: DailyWorkStateModel) {
        return DailyWork.filterByToday(state.daily_work)
    }

    @Selector()
    static getScheduledByDate(state: DailyWorkStateModel, date: string, date_format: string) {
        return DailyWork.filterByDate(state.daily_work, date, date_format)
    }

    @Action(DailyWorkActions.Refresh)
    async refresh({patchState}: StateContext<DailyWorkStateModel>, {  }:DailyWorkActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }
    
    @Action(DailyWorkActions.Init)
    init({setState, getState}: StateContext<DailyWorkStateModel>, { payload }:DailyWorkActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new DailyWorkActions.Refresh())
    }

    @Action(DailyWorkActions.Set)
    set({setState}: StateContext<DailyWorkStateModel>, { payload }:DailyWorkActions.Set) {             
        setState({      
            is_refreshing: false,      
            daily_work: payload
        })
    }
}
