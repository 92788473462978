export class App {
  is_refreshing: boolean
  
  constructor() {    
  }

  static buildOne(obj: any): App {
    let target: App = Object.assign(new App(), obj)
    return target
  }

  static buildMany(objs: any[]): App[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(App.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

