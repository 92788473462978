import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  loading: any

  constructor(
    public loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) {}

  async startWait(msg: string) {
    const loading = await this.loadingCtrl.create({message: msg})
    await loading.present()
  }

  async stopWait() {
    await this.loadingCtrl.dismiss()
  }

  async showAlert(title: string, msg: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: ['OK']
    })
    return alert.present()
  }

  async showToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000
    })
    return toast.present()
  }

  async confirmPrompt(header: string, message: string, ok_text: string, cancel_text: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const buttons = [];
      if (ok_text  && ok_text.length) {
        buttons.push({text: ok_text, handler: () => {return resolve(true) }});
      }
      if (cancel_text  && cancel_text.length) {
        buttons.push({text: cancel_text, role: 'cancel', handler: () => {return resolve(false) }});
      }
      const alert = await this.alertCtrl.create({
        header: header,
        message: message,
        backdropDismiss: false,
        buttons        
      })
      await alert.present()
    })
  }
}
