import { v4 as uuid } from 'uuid'

export class SalesOrderItem {  
  id: number
  name: string
  tag: string
  ist: boolean
  prv_ist: boolean

  constructor() {
  }

  public async setViewModelFields()
  {

  }

  static buildOne(obj: any): SalesOrderItem {
    let target: SalesOrderItem = Object.assign(new SalesOrderItem(), obj)
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): SalesOrderItem[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(SalesOrderItem.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

