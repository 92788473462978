import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { SiteActions } from '../actions/site.actions';
import { Site } from '../models/site.model';
import { SiteService } from 'src/app/services/site.service';
import { StorageService } from 'src/app/services/storage.service';

export class SiteStateModel {
    is_refreshing: boolean
    site: Site[]
}

@State<SiteStateModel>({
    name: 'site',
    defaults: {
        is_refreshing: true,
        site: []
    }
})
@Injectable()
export class SiteState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private siteService: SiteService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<SiteStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {          
        if (this.storageService.is_initialized) {
            const state_model: SiteStateModel = change.currentValue
            this.storageService.set('sites', JSON.stringify(state_model))    
        }    
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('sites');        
        if (json) {
            const state_model: SiteStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new SiteActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new SiteActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.siteService.getAll()    
        if (objs != null) {
          const many = Site.buildMany(objs)                  
          this.store.dispatch(new SiteActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: SiteStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: SiteStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: SiteStateModel) {
        return state.site
    }

    @Action(SiteActions.Refresh)
    async refresh({patchState}: StateContext<SiteStateModel>, {  }:SiteActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(SiteActions.Init)
    init({setState, getState}: StateContext<SiteStateModel>, { payload }:SiteActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new SiteActions.Refresh())
    }
    
    @Action(SiteActions.Set)
    set({setState}: StateContext<SiteStateModel>, { payload }:SiteActions.Set) {             
        setState({      
            is_refreshing: false,      
            site: payload
        })
    }
}
