export class Customer {
  id: number
  name: string
  
  constructor() {
  }

  static buildOne(obj: any): Customer {
    let target: Customer = Object.assign(new Customer(), obj)
    return target
  }

  static buildMany(objs: any[]): Customer[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Customer.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

