import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { ContactActions } from '../actions/contact.actions';
import { Contact } from '../models/contact.model';
import { ContactService } from 'src/app/services/contact.service';
import { StorageService } from 'src/app/services/storage.service';

export class ContactStateModel {
    is_refreshing: boolean
    contact: Contact[]
}

@State<ContactStateModel>({
    name: 'contact',
    defaults: {
        is_refreshing: true,
        contact: []
    }
})
@Injectable()
export class ContactState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private contactService: ContactService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<ContactStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                       
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }   

    async ngxsOnChanges(change: NgxsSimpleChange) {                     
        if (this.storageService.is_initialized) {
            const state_model: ContactStateModel = change.currentValue
            this.storageService.set('contacts', JSON.stringify(state_model))   
        }  
    }

    async reloadStateFromStorage() {        
        const json: string = await this.storageService.get('contacts');        
        if (json) {
            const state_model: ContactStateModel = JSON.parse(json)            
            if (state_model) {                
                this.store.dispatch(new ContactActions.Init(state_model));
                return
            } 
        }
        this.store.dispatch(new ContactActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.contactService.getAll()    
        if (objs != null) {
          const many = Contact.buildMany(objs)                  
          this.store.dispatch(new ContactActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: ContactStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: ContactStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: ContactStateModel) {
        return state.contact
    }

    @Action(ContactActions.Refresh)
    async refresh({patchState}: StateContext<ContactStateModel>, {  }:ContactActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(ContactActions.Init)
    init({setState, getState}: StateContext<ContactStateModel>, { payload }:ContactActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new ContactActions.Refresh())
    }
    
    @Action(ContactActions.Set)
    set({setState}: StateContext<ContactStateModel>, { payload }:ContactActions.Set) {             
        setState({    
            is_refreshing: false,        
            contact: payload
        })
    }
}
