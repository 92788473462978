import { Technician } from "../models/technician.model";
import { TechnicianStateModel } from "../state/technician.state";

export namespace TechnicianActions {
    export class Refresh {
        static readonly type = '[API-Technician] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Technician] Init'
        constructor(public payload: TechnicianStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Technician] Set'
        constructor(public payload: Technician[]) {}
    }
}
