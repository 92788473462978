import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Form } from '../state-man/models/form.model';
import { FormSalesOrderItem } from '../state-man/models/form-sales-order-item.model';
import { LoggerService } from './logger.service';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getMine (include_children: boolean = true) : Promise<Form[] | null> {  
    // We do this on the backend now.
    include_children = false
    try {      
      const ret = await this.apiService.apiReturnsAny('get', 'form/mine/')           
      const forms = (ret && Array.isArray(ret)) ? Form.buildMany(ret) : null
      if (!forms) return null

      if (include_children) {
        const promises = []
        for (const form of forms) {
          promises.push(this.getAndSetChildren(form))
        }
        await Promise.all(promises)        
      }

      return forms      
    } 
    catch (error) {
      LoggerService.log(error + 'form/mine/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  private async getAndSetChildren(form: Form) {
    const sales_order_items = await this.getFormSalesOrderItems(form.id)
    if (sales_order_items) {
      form.sales_order_items = sales_order_items
    } else {
      throw new Error('Error getting sales order items!')
    }    
  }

  private async getFormSalesOrderItems(form_id: number) : Promise<FormSalesOrderItem[] | null> {
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'form/items/' + form_id)      
      return (ret && Array.isArray(ret)) ? FormSalesOrderItem.buildMany(ret) : null
    } 
    catch (error) {
      LoggerService.log(error + 'form/items/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }
  }

  public async addForm(form: Form) : Promise<Form | null> {
    try {
      const body = form      
      return await this.apiService.apiReturnsAny('post', 'form/', body, 'application/json')   
    } 
    catch (error) {
      LoggerService.log(error + 'post form/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }   

  public async updateForm(form: Form) : Promise<Form | null> {
    try {
      const body = form
      const ret = await this.apiService.apiReturnsAny('put', 'form/' + form.id, body, 'application/json')         
      return ret
    } catch (error) {
      LoggerService.log(error + 'put form/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async deleteForm(form: Form) : Promise<any> {
    try {
      const body = form
      const ret = await this.apiService.apiReturnsAny('delete', 'form/' + form.id, body, 'application/json')               
      return ret
    } catch (error) {
      LoggerService.log(error + 'delete form/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  
}
