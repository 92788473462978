import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SalesOrderItem } from '../state-man/models/sales-order-item.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderItemService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getAll() : Promise<SalesOrderItem[] | null> {  
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'sales_order_item/')             
      return (ret && Array.isArray(ret)) ? SalesOrderItem.buildMany(ret) : null
    } 
    catch (error) {    
      LoggerService.log(error + 'sales_order_item/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')      
      return null
    }
  }  
}
