export class Area {
  id: number
  name: string
  
  constructor() {
  }

  static buildOne(obj: any): Area {
    let target: Area = Object.assign(new Area(), obj)
    return target
  }

  static buildMany(objs: any[]): Area[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Area.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

