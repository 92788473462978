import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { WorkOrder } from '../state-man/models/work-order.model';
import { LoggerService } from './logger.service';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getScheduled () : Promise<WorkOrder[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'work_order/scheduled/')           
      return (ret && Array.isArray(ret)) ? WorkOrder.buildMany(ret) : null
    } 
    catch (error) {          
      LoggerService.log(error + ': work_order/scheduled/')    
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }
  }  
  
}
