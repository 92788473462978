import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Dsr } from '../state-man/models/dsr.model';
import { ServiceEntry } from '../state-man/models/service-entry.model';
import { LoggerService } from './logger.service';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class DsrService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getMine (include_children: boolean = true) : Promise<Dsr[] | null> {   
    // We do this on the backend now.
    include_children = false
    try {      
      const ret = await this.apiService.apiReturnsAny('get', 'dsr/mine/')          
      const dsrs = (ret && Array.isArray(ret)) ? Dsr.buildMany(ret) : null

      if (!dsrs) return null
      if (include_children) {

        const promises = []
        for (const dsr of dsrs) {
          promises.push(this.getAndSetChildren(dsr))
        }
        await Promise.all(promises)           
      }
      return dsrs      
    } catch (error) {
      LoggerService.log(error + 'dsr/mine')
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }    
  }  

  private async getAndSetChildren(dsr: Dsr) {
    const service_entries = await this.getServiceEntries(dsr.id)
    if (service_entries != null) {
      dsr.service_entries = service_entries
    } else {  
      throw new Error('Error getting dsr service entries!')
    }    
  }

  public async getServiceEntries(dsr_id: number) : Promise<ServiceEntry[] | null> {
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'dsr/list_service_entries/' + dsr_id)          
      return (ret && Array.isArray(ret)) ? ServiceEntry.buildMany(ret) : null
    } catch (error) {
      LoggerService.log(error + 'dsr/list_service_entries')
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }
  }

  public async addDsr(dsr: Dsr) : Promise<Dsr | null> {
    try {
      const body = dsr
      const obj = await this.apiService.apiReturnsAny('post', 'dsr/', body, 'application/json')   
      await this.getAndSetChildren(obj)
      return obj
    } catch (error) {
      LoggerService.log(error + 'post dsr/')
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }    
  }   

  public async updateDsr(dsr: Dsr) : Promise<Dsr | null> {
    try {          
      const body = dsr
      const obj = await this.apiService.apiReturnsAny('put', 'dsr/' + dsr.id, body, 'application/json')         
      await this.getAndSetChildren(obj)
      return obj      
    } catch (error) {
      LoggerService.log(error + 'put dsr/')    
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }    
  }  

  public async deleteDsr(dsr: Dsr) : Promise<any> {
    try {
      const body = dsr
      const ret = await this.apiService.apiReturnsAny('delete', 'dsr/' + dsr.id, body, 'application/json')               
      return ret
    } catch (error) {
      LoggerService.log(error + 'delete dsr/')    
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')  
      return null
    }    
  }  
}
