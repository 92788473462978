//
// ===== File globals.ts
//
"use strict";

declare var appVersion: string;

export const app_lat = 30.3012427;
export const app_lon = -96.9130256;
export const app_logo = "assets/icons/logo3.svg";
export const app_logo_dark = "assets/icons/logo_dark.png";
export const app_version_number: string = appVersion;
export const app_version: string = "Version " + app_version_number;
export const app_version_last_checked = "Version Last Checked:";
export const app_copyright = "Copyright Roto-Versal Energy Services";
export const app_copyright_start_year = 2023;
export const app_title = "Roto Track Mobile";
export const app_description = "Your mobile tech solution";
export const app_api_timeout_ms = 30000;
export const app_are_you_sure_message = "Are you sure?";
export const app_content_submit_is_final = "You will not be able to edit MRF once submitted";
export const app_save_button = "Save";
export const app_submit_button = "Submit";
export const app_cancel_button = "Cancel";
export const app_delete_button = "Delete";
export const app_yes_button = "Yes";
export const app_ok_button = "OK";
export const app_close_button = "Close";
export const app_old_ios_version =
  "This device has an old iOS version that is not supported.  You must have version 11.3 or later.  " +
  "Please update your iOS version and try again.  Your version is: ";
export const app_form_error = "Please correct your inputs and try again.";
export const app_accuracy_required = 10;
export const app_form_error_accuracy: string =
  "GPS accuracy too low.  Must be less than " + app_accuracy_required + " meters.";
export const app_error_message_title = "Error";
export const app_error_loading_data = "There was an error loading data.  Press Reload Data to try again.";
export const app_success_uploading_data = "Your data was uploaded successfully.";
export const app_success_deleting_data = "Your data was deleted successfully.";
export const app_error_uploading_pending_data =
  "Unable to upload pending data at this time.  Data will upload automatically when a " +
  "connection is available, or you may try again.";
export const app_error_uploading_data_queued =
  "Unable to upload data at this time.  Your data was saved locally and will upload " +
  "automatically when a connection is available.";
export const app_error_uploading_data_try_again = "An error occurred while uploading data.  Please try again.";
export const app_confirm_delete_message = "Delete this item?";
export const app_using_cached_data = "Unable to load all data from server, so using cached data.";
export const app_offline_data_header = "Data Pending Upload";
export const app_offline_data_message =
  " items are pending upload.  Once you are reconnected to the network, your data will be " +
  "uploaded automatically.  Or you can click the button below to retry.";
export const app_offline_data_retry_button = "Retry Upload";
export const app_delete_error_need_online = "Unable to delete item.  Please try again when connected to the Internet";
export const app_load_photos_error_need_online =
  "Unable to load photos when disconnected.  Please try again when connected to the " + "Internet";

export const welcome_login = "Login";
export const welcome_reload_button = "Reload";
export const welcome_install_button = "Install";
export const welcome_add_button = "Add";
export const welcome_upgrade_message = "New update available! Click Reload to load the latest changes.";
export const welcome_a2hs_message = "Add to Homescreen?";
export const welcome_check_version_timeout_ms = 60000;
export const welcome_update_message_title = "Update Available";
export const welcome_update_message = "A required application update is ready to download.  Please click OK to update.";

export const login_username = "Username";
export const login_password = "Password";
export const login_login = "Login";

export const about_title = "About";
export const about_logout_button = "Logout";
export const about_check_for_updates_button = "Check for Updates";
export const about_network_status_label = "Network Status:";
export const about_lat_lon_label = "Lat/Lon:";
export const about_logged_in_as_label = "Logged in as:";

export const about_what_is_new_button = "What's New?";
export const about_confirm_logout_message =
  "You will need to re-enter your username and password if you logout.  " + "Are you sure you want to logout?";

export const app_allowed_input_pattern =
  "[a-zA-Z0-9`~!@#$%^&*()-_=+{}\\|;:'\",<.>/?×÷“‘’″′\r\n]+[a-zA-Z0-9`~!@#$%^&*()-_=+{}\\|;:'\",<.>/?×÷“‘’″′\r\n ]*$";
export const app_allowed_input_chars =
  "Only alphanumeric characters, most symbols and spaces allowed, and cannot start with a space.";

export const app_allowed_alphanumeric_pattern = "[a-zA-Z0-9.]*$";
export const app_allowed_alphanumeric_chars = "Only alphanumeric characters allowed.";
export const app_allowed_numeric_pattern = "[0-9]*$";
export const app_allowed_numeric_chars = "Only numeric characters allowed.";

export const whats_new: string[] = ["Fixes for offline"];

export const whats_new_previous: string[] = [
  "Bug fixes all forms and VRT missing field",
  "Bug fixes on timesheet and UI fixes",
  "Finish view of work schedule, form bug fix to show work date",
  "Bug fix on forms",
  "Bug fixes for DSRs.",
  "Bug fix for form status change.",
  "Fixes to create and view DSRs",
  "Added missing fields to VRT and TOF",
  "Adding missing fields to VRT and TOF",
  "Fixed DSR mileage and time to be user friendly",
  "Work on styles",  
];

export const empty_sig: string =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAd8AAABzCAYAAADZqQh5AAAAAXNSR0IArs4c6QAABRlJREFUeF7t171NK1EURtFxB2TgnJ8m3D7UQIBjO4MOBokahq0j3fUKeN943SNtcdr3fd/8I0CAAAECBDKBk/hm1oYIECBAgMCfgPg6BAIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAuIbwxujgABAgQIiK8bIECAAAECsYD4xuDmCBAgQICA+LoBAgQIECAQC4hvDG6OAAECBAiIrxsgQIAAAQKxgPjG4OYIECBAgID4ugECBAgQIBALiG8Mbo4AAQIECIivGyBAgAABArGA+Mbg5ggQIECAgPi6AQIECBAgEAscGt/P+8/28XWLf4I5AgQIECDwvwKXl/P2+vhw2Mix8b19b+/X+2Ef5z8iQIAAAQITBC7P5+3taWh8JwD5BgIECBAgMF3g0L98p/9Y30eAAAECBCYIiO+EV/ANBAgQILCUgPgu9dx+LAECBAhMEBDfCa/gGwgQIEBgKQHxXeq5/VgCBAgQmCAgvhNewTcQIECAwFIC4rvUc/uxBAgQIDBBQHwnvIJvIECAAIGlBMR3qef2YwkQIEBggoD4TngF30CAAAECSwmI71LP7ccSIECAwAQB8Z3wCr6BAAECBJYSEN+lntuPJUCAAIEJAr8ypta3Kg7ZzQAAAABJRU5ErkJggg==";
