import { AssetCategory } from "../models/asset-category.model";
import { AssetCategoryStateModel } from "../state/asset-category.state";

export namespace AssetCategoryActions {
    export class Refresh {
        static readonly type = '[API-AssetCategory] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-AssetCategory] Init'
        constructor(public payload: AssetCategoryStateModel) {}
    }
    export class Set {
        static readonly type = '[API-AssetCategory] Set'
        constructor(public payload: AssetCategory[]) {}
    }
}
