export class Pto {
    sick_hours_given_ytd: number
    sick_hours_used_ytd: number    
    vacation_hours_offer: number
    vacation_hours_carry_over: number
    vacation_hours_given_ytd: number
    vacation_hours_used_ytd: number    

    constructor() {
    }
  
    static buildOne(obj: any): Pto {
      let target: Pto = Object.assign(new Pto(), obj)
      return target
    }
  
    static buildMany(objs: any[]): Pto[] {
      const lobjs = []
      for (const i in objs) {
        if (objs.hasOwnProperty(i)) {
          lobjs.push(Pto.buildOne(objs[i]))
        }
      }
      return lobjs
    }
}
