import { JobType } from "../models/job-type.model";
import { JobTypeStateModel } from "../state/job-type.state";

export namespace JobTypeActions {
    export class Refresh {
        static readonly type = '[API-JobType] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-JobType] Init'
        constructor(public payload: JobTypeStateModel) {}
    }
    export class Set {
        static readonly type = '[API-JobType] Set'
        constructor(public payload: JobType[]) {}
    }
}
