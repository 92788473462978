import { Dsr } from "../models/dsr.model";
import { UuidAndId } from "../models/uuid-and-id";
import { UuidAndIdAndServiceEntries } from "../models/uuid-and-id-and-service-entries";
import { DsrStateModel } from "../state/dsr.state";

export namespace DsrActions {
    export class Refresh {
        static readonly type = '[API-DSR] Refresh'
        constructor() {}
    }
    export class IncrementPendingCount {
        static readonly type = '[Network Service-DSR] Increment Pending Count'
        constructor() {}
    } 
    export class Init {
        static readonly type = '[Storage-DSR] Init'
        constructor(public payload: DsrStateModel) {}
    }
    export class Set {
        static readonly type = '[API-DSR] Set'
        constructor(public payload: Dsr[]) {}
    }
    export class Merge {
        static readonly type = '[API-DSR] Merge'
        constructor(public payload: Dsr[]) {}
    }
    export class AddOne {
        static readonly type = '[UI-DSR] Add One'
        constructor(public payload: Dsr) {}
    }    
    export class UpdateOne {
        static readonly type = '[UI-DSR] Update One'
        constructor(public payload: Dsr) {}
    }       
    export class DeleteOne {
        static readonly type = '[UI-DSR] Delete One'
        constructor(public payload: Dsr) {}
    }     
    export class ApiAddedOne {
        static readonly type = '[API-DSR] Added One'
        constructor(public payload: UuidAndIdAndServiceEntries) {}
    }
    export class ApiUpdatedOne {
        static readonly type = '[API-DSR] Updated One'
        constructor(public payload: UuidAndIdAndServiceEntries) {}
    }
    export class ApiDeletedOne {
        static readonly type = '[API-DSR] Deleted One'
        constructor(public payload: UuidAndId) {}
    }
    export class UpdateServiceEntriesApproval {
        static readonly type = '[UI-DSR] UpdateServiceEntriesApproval'
        constructor(public payload: Dsr) {}
    }     
    export class ApiUpdatedServiceEntriesApproval {
        static readonly type = '[API-DSR] UpdatedServiceEntriesApproval'
        constructor(public payload: Dsr) {}
    }      
}
