import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  endpoint: string;

  constructor(
      private apiService: ApiService
  ) {
    this.endpoint = 'app_settings/';
  }

  public getAll() {
    return this.apiService.apiReturnsAny('get', this.endpoint, { });
  }

}
