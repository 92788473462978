import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Wor } from '../state-man/models/wor.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class WorService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getMine () : Promise<Wor[] | null> {   
    try {      
      const ret = await this.apiService.apiReturnsAny('get', 'work_order_request/mine/')        
      const data = (ret && Array.isArray(ret)) ? Wor.buildMany(ret) : null
      if (!data) return null      
      return data      
    } catch (error) {
      LoggerService.log(error + 'work_order_request/mine/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async addWor(wor: Wor) : Promise<Wor | null> {
    try {
      const body = wor
      return await this.apiService.apiReturnsAny('post', 'work_order_request/', body, 'application/json')   
    } catch (error) {
      LoggerService.log(error + 'post work_order_request/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async updateWor(wor: Wor) : Promise<Wor | null> {
    try {
      const body = wor
      const ret = await this.apiService.apiReturnsAny('put', 'work_order_request/' + wor.id, body, 'application/json')         
      return ret
    } catch (error) {
      LoggerService.log(error + 'put work_order_request/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async deleteWor(wor: Wor) : Promise<any> {
    try {
      const body = wor
      const ret = await this.apiService.apiReturnsAny('delete', 'work_order_request/' + wor.id, body, 'application/json')               
      return ret
    } catch (error) {
      LoggerService.log(error + 'delete work_order_request/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  
  
}
