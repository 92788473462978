import { WorkOrder } from "../models/work-order.model";
import { WorkOrderStateModel } from "../state/work-order.state";

export namespace WorkOrderActions {
    export class Refresh {
        static readonly type = '[API-WorkOrder] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-WorkOrder] Init'
        constructor(public payload: WorkOrderStateModel) {}
    }
    export class Set {
        static readonly type = '[API-WorkOrder] Set'
        constructor(public payload: WorkOrder[]) {}
    }
}
