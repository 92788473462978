import { Form } from "../models/form.model";
import { UuidAndId } from "../models/uuid-and-id";
import { FormStateModel } from "../state/form.state";

export namespace FormActions {
    export class Refresh {
        static readonly type = '[API-FORM] Refresh'
        constructor() {}
    }
    export class IncrementPendingCount {
        static readonly type = '[Network Service-FORM] Increment Pending Count'
        constructor() {}
    } 
    export class Init {
        static readonly type = '[API-FORM] Init'
        constructor(public payload: FormStateModel) {}
    }
    export class Set {
        static readonly type = '[API-FORM] Set'
        constructor(public payload: Form[]) {}
    }
    export class Merge {
        static readonly type = '[API-FORM] Merge'
        constructor(public payload: Form[]) {}
    }
    export class AddOne {
        static readonly type = '[UI-FORM] Add One'
        constructor(public payload: Form) {}
    }      
    export class UpdateOne {
        static readonly type = '[UI-FORM] Update One'
        constructor(public payload: Form) {}
    }       
    export class DeleteOne {
        static readonly type = '[UI-FORM] Delete One'
        constructor(public payload: Form) {}
    }   
    export class ApiAddedOne {
        static readonly type = '[API-FORM] Added One'
        constructor(public payload: UuidAndId) {}
    }
    export class ApiUpdatedOne {
        static readonly type = '[API-FORM] Updated One'
        constructor(public payload: UuidAndId) {}
    }
    export class ApiDeletedOne {
        static readonly type = '[API-FORM] Deleted One'
        constructor(public payload: UuidAndId) {}
    }
}
