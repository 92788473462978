import { FormType } from "../models/form-type.model";
import { FormTypeStateModel } from "../state/form-type.state";

export namespace FormTypeActions {
    export class Refresh {
        static readonly type = '[API-FormType] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-FormType] Init'
        constructor(public payload: FormTypeStateModel) {}
    }
    export class Set {
        static readonly type = '[API-FormType] Set'
        constructor(public payload: FormType[]) {}
    }
}
