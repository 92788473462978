import { Site } from "../models/site.model";
import { SiteStateModel } from "../state/site.state";

export namespace SiteActions {
    export class Refresh {
        static readonly type = '[API-Site] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Site] Init'
        constructor(public payload: SiteStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Site] Set'
        constructor(public payload: Site[]) {}
    }
}
