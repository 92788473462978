import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { LoggerService } from './logger.service';
import { Subject } from 'rxjs';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null
  is_initialized: boolean = false
  events: any

  constructor(
    private storage: Storage,
    private uiService: UiService
    ) {
      this.events = new Subject()
      this.init()
  }

  async init() {   
    const storage = await this.storage.create()
    this._storage = storage
    this.is_initialized = true    
    this.events.next(true)
  }

  public notify_if_init() {
    if (this.is_initialized) {
      this.events.next(true)
    }
  }

  public async set(key: string, value: any) {
    try {
      const val = await this._storage?.get(key)     
      if (!this.is_initialized) throw new Error('set called, not initialized. key: ' + key)  
      await this._storage?.set(key, value)      
    } catch (error) {      
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      throw(error)
    }
  }

  public async get(key: string) {       
    try {     
        const val = await this._storage?.get(key)     
        if (!this.is_initialized) throw new Error('get called, not initialized. key: ' + key)   
        return val      
    }     
    catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      throw(error)
    }    
  }

  public async clear() {    
    try {      
      await this._storage?.clear()
    } catch (error) {      
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    }
  }

  public async remove(key: string) {
    try {      
      await this._storage?.remove(key)         
    } catch (error) {
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    }
  }

  public async length() {
    try {      
      return await this._storage?.length()
    } catch (error) {
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return 0
    }
  }
}
