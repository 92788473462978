import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { WorkScheduleActions } from '../actions/work-schedule.actions';
import { WorkSchedule } from '../models/work-schedule.model';
import { WorkScheduleService } from 'src/app/services/work-schedule.service';
import { StorageService } from 'src/app/services/storage.service';

export class WorkScheduleStateModel {
    is_refreshing: boolean
    work_schedules: WorkSchedule[]
}

@State<WorkScheduleStateModel>({
    name: 'work_schedule',
    defaults: {
        is_refreshing: true,
        work_schedules: []
    }
})
@Injectable()
export class WorkScheduleState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private workscheduleService: WorkScheduleService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<WorkScheduleStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {       
        if (this.storageService.is_initialized) {
            const state_model: WorkScheduleStateModel = change.currentValue
            this.storageService.set('work_schedules', JSON.stringify(state_model))    
        }      
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('work_schedules');        
        if (json) {
            const state_model: WorkScheduleStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new WorkScheduleActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new WorkScheduleActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.workscheduleService.getScheduled()    
        if (objs != null) {
          const many = WorkSchedule.buildMany(objs)                  
          this.store.dispatch(new WorkScheduleActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: WorkScheduleStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: WorkScheduleStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: WorkScheduleStateModel) {
        return state.work_schedules
    }

    @Action(WorkScheduleActions.Refresh)
    async refresh({patchState}: StateContext<WorkScheduleStateModel>, {  }:WorkScheduleActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(WorkScheduleActions.Init)
    init({setState, getState}: StateContext<WorkScheduleStateModel>, { payload }:WorkScheduleActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new WorkScheduleActions.Refresh())
    }
    
    @Action(WorkScheduleActions.Set)
    set({setState}: StateContext<WorkScheduleStateModel>, { payload }:WorkScheduleActions.Set) {             
        setState({       
            is_refreshing: false,     
            work_schedules: payload
        })
    }
}
