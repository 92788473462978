import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Technician } from '../state-man/models/technician.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getAll () : Promise<Technician[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'user/technicians/')             
      return (ret && Array.isArray(ret)) ? Technician.buildMany(ret) : null
    } 
    catch (error) {    
      LoggerService.log(error + 'user/technicians/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')      
      return null
    }
  }  
  
}
