export class Site {
  id: number
  customer_id: number
  site_prefix: string
  site_name: string
  unit_number: string 
  engine_driver_make: string
  engine_driver_model: string
  engine_driver_sn: string  
  compressor_make: string
  compressor_model: string
  compressor_sn: string  
  control_panel_make: string
  control_panel_model: string
  control_panel_sn: string
  city_state: string
  tz: string
  
  constructor() {
  }

  static buildOne(obj: any): Site {
    let target: Site = Object.assign(new Site(), obj)
    return target
  }

  static buildMany(objs: any[]): Site[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Site.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

