import { Photo } from './photo.model'
import { v4 as uuid } from 'uuid'

export class JsaPhoto extends Photo {
  // Model
  jsa_id = 0

  public async setViewModelFields()
  {
    if (this.photo_data && this.photo_type) {
      this.base64 = 'data:' + this.photo_type + ';base64,' + this.photo_data
      this.base64_sz = this.base64
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
  }

  constructor(
   ) {
    super()
  }

  static override async buildOne(obj: any): Promise<JsaPhoto> {
    let target: JsaPhoto = Object.assign(new JsaPhoto(), obj)
    await target.setViewModelFields()
    return target
  }

  static override async buildMany(objs: any): Promise<JsaPhoto[]> {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(await JsaPhoto.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

