import { AssetField } from "../models/asset-field.model";
import { AssetFieldStateModel } from "../state/asset-field.state";

export namespace AssetFieldActions {
    export class Refresh {
        static readonly type = '[API-AssetField] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-AssetField] Init'
        constructor(public payload: AssetFieldStateModel) {}
    }
    export class Set {
        static readonly type = '[API-AssetField] Set'
        constructor(public payload: AssetField[]) {}
    }
}
