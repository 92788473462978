import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Contact } from '../state-man/models/contact.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }
  
  public async getAll () : Promise<Contact[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'contact/active/')         
      return (ret && Array.isArray(ret)) ? Contact.buildMany(ret) : null
    } 
    catch (error) {    
      LoggerService.log(error + 'contact/active')    
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }
  }  

}
