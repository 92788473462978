import { DateTime } from "luxon"

export class DailyWork {
  technician_id: number
  work_date: string
  work_schedule_status_id: number
  work_order_id: number
  work_order_number: string
  work_schedule_status_description: string
  customer: string
  site_name: string
  unit_number: string
  
  constructor() {
  }

  static buildOne(obj: any): DailyWork {
    let target: DailyWork = Object.assign(new DailyWork(), obj)
    return target
  }

  static buildMany(objs: any[]): DailyWork[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(DailyWork.buildOne(objs[i]))
      }
    }
    return lobjs
  }

  static filterByDate(daily_work: DailyWork[], date: string, date_format: string) {
    if (!daily_work || !Array.isArray(daily_work)) daily_work = []    
    const filtered = daily_work.filter((f) => {       
      const theday = DateTime.fromFormat(date, date_format).toFormat('M/d/yyyy')    
      const due = DateTime.fromISO(f.work_date, { zone: "utc" }).toFormat('M/d/yyyy')   
      return theday === due
    })                
    return filtered
  }

  static filterByToday(daily_work: DailyWork[]) {    
    if (!daily_work || !Array.isArray(daily_work)) daily_work = []       
    
    const filtered = daily_work.filter((f) => {       
      const today = DateTime.now().toFormat('M/d/yyyy')    
      const due = DateTime.fromISO(f.work_date, { zone: "utc" }).toFormat('M/d/yyyy')   
      return today === due
    })                
    return filtered
  }
}

