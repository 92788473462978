export class JobType {
  id: number
  name: string  
  
  constructor() {
  }

  static buildOne(obj: any): JobType {
    let target: JobType = Object.assign(new JobType(), obj)
    return target
  }

  static buildMany(objs: any[]): JobType[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(JobType.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

