import { Customer } from "../models/customer.model";
import { CustomerStateModel } from "../state/customer.state";

export namespace CustomerActions {
    export class Refresh {
        static readonly type = '[API-Customer] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Customer] Init'
        constructor(public payload: CustomerStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Customer] Set'
        constructor(public payload: Customer[]) {}
    }
}
