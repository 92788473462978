import { WorkOrder } from "./work-order.model"

export class WorkSchedule {
  id: number  
  description: string
  work_date: string
  assigned_techs: string
  work_order: WorkOrder
  company_name: string
  
  constructor() {
    this.assigned_techs = ""
  }

  public setViewModelFields()
  {
    if (this.work_order) {
      this.work_order = WorkOrder.buildOne(this.work_order)
      if (this.work_order.service_details) {
        const arr = this.work_order.service_details.map((sd) => sd.technician); 
        this.assigned_techs = arr.join(", ")
      }      
    }
  }

  static buildOne(obj: any): WorkSchedule {
    const clone = Object.assign(new WorkSchedule(), obj)
    if (clone.work_orders) delete clone.work_orders
    let target: WorkSchedule = clone
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): WorkSchedule[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(WorkSchedule.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

