import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { Technician } from '../models/technician.model';
import { TechnicianService } from 'src/app/services/technician.service';
import { TechnicianActions } from '../actions/technician.actions';
import { StorageService } from 'src/app/services/storage.service';

export class TechnicianStateModel {
    is_refreshing: boolean
    technician: Technician[]
}

@State<TechnicianStateModel>({
    name: 'technician',
    defaults: {
        is_refreshing: true,
        technician: []
    }
})
@Injectable()
export class TechnicianState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private technicianService: TechnicianService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<TechnicianStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {           
        if (this.storageService.is_initialized) {
            const state_model: TechnicianStateModel = change.currentValue
            this.storageService.set('technicians', JSON.stringify(state_model))    
        }    
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('technicians');        
        if (json) {
            const state_model: TechnicianStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new TechnicianActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new TechnicianActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.technicianService.getAll()    
        if (objs != null) {
          const many = Technician.buildMany(objs)                  
          this.store.dispatch(new TechnicianActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: TechnicianStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: TechnicianStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: TechnicianStateModel) {
        return state.technician
    }

    @Action(TechnicianActions.Refresh)
    async refresh({patchState}: StateContext<TechnicianStateModel>, {  }:TechnicianActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(TechnicianActions.Init)
    init({setState, getState}: StateContext<TechnicianStateModel>, { payload }:TechnicianActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new TechnicianActions.Refresh())
    }
    
    @Action(TechnicianActions.Set)
    set({setState}: StateContext<TechnicianStateModel>, { payload }:TechnicianActions.Set) {             
        setState({    
            is_refreshing: false,        
            technician: payload
        })
    }
}
