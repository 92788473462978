import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DailyWork } from '../state-man/models/daily-work.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class DailyWorkService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getScheduled (work_date: string='') : Promise<DailyWork[] | null> {    
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'technician_daily_work/scheduled/' + work_date)      
      return (ret && Array.isArray(ret)) ? DailyWork.buildMany(ret) : null
    } 
    catch (error) {   
      LoggerService.log(error + 'technician_daily_work/scheduled/')    
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')     
      return null
    }
    
  }  
}
