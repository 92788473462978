import { v4 as uuid } from 'uuid'

export class FormSalesOrderItem {  
  form_id: number
  sales_order_item_id: number  
  quantity: number
  price: number
  name: string

  constructor() {
  }

  public async setViewModelFields()
  {

  }

  static buildOne(obj: any): FormSalesOrderItem {
    let target: FormSalesOrderItem = Object.assign(new FormSalesOrderItem(), obj)
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): FormSalesOrderItem[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(FormSalesOrderItem.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

