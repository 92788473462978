import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { AppConfigActions } from '../actions/app-config.actions';
import { AppConfig } from '../models/app-config.model';

export class AppConfigStateModel {
    app_config: AppConfig
}

@State<AppConfigStateModel>({
    name: 'app_config',
    defaults: {
        app_config: AppConfig.buildOne({state_version: ''})
    }
})
@Injectable()
export class AppConfigState {
    constructor(                
    ) {}

    @Selector()
    static getAppConfig(state: AppConfigStateModel) {
        return state.app_config
    }

    @Selector()
    static getState(state: AppConfigStateModel) {
        return state
    }
    
    @Action(AppConfigActions.Set)
    set({setState}: StateContext<AppConfigStateModel>, { payload }:AppConfigActions.Set) {             
        setState({            
            app_config: payload
        })
    }
}
