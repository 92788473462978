import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Site } from '../state-man/models/site.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getAll () : Promise<Site[] | null> {      
    try {
      const ret = await await this.apiService.apiReturnsAny('get', 'site/active/')         
      return (ret && Array.isArray(ret)) ? Site.buildMany(ret) : null
    } 
    catch (error) {     
      LoggerService.log(error + 'site/active/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')     
      return null
    }
  }  
  
}
