import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Mrf } from '../state-man/models/mrf.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class MrfService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getMine () : Promise<Mrf[] | null> {   
    try {      
      const ret = await this.apiService.apiReturnsAny('get', 'mrf/mine/')       
      const data = (ret && Array.isArray(ret)) ? Mrf.buildMany(ret) : null
      if (!data) return null      
      return data      
    } catch (error) {
      LoggerService.log(error + 'mrf/mine/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async addMrf(mrf: Mrf) : Promise<Mrf | null> {
    try {
      const body = mrf
      return await this.apiService.apiReturnsAny('post', 'mrf/', body, 'application/json')
    } catch (error) {
      LoggerService.log(error + 'post mrf/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async updateMrf(mrf: Mrf) : Promise<Mrf | null> {
    try {      
      const body = {data: mrf}
      const ret = await this.apiService.apiReturnsAny('put', 'mrf/' + mrf.id, body, 'application/json')         
      return ret
    } catch (error) {
      LoggerService.log(error + 'put mrf/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }    
  }  

  public async deleteMrf(mrf: Mrf) : Promise<any> {
    try {
      const body = mrf
      const ret = await this.apiService.apiReturnsAny('delete', 'mrf/' + mrf.id, body, 'application/json')                     
      return ret
    } catch (error) {    
      LoggerService.log(error + 'delete mrf/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')      
      return null
    }    
  }  
  
}
