import { DateTime } from 'luxon';
import { JsaPhoto } from './jsa-photo.model'

export class JsaAndPhoto {
  jsa_id: number
  photo: JsaPhoto
}

export class Jsa {
  id = 0
  due_date = ''
  is_completed = false
  is_scheduled = false
  type = ''
  work_order_number = ''
  customer = ''
  site_name = ''
  unit_number = ''
  job_type: string
  due_date_vm = ''
  due_date_class_vm = ''
  photos: JsaPhoto[] = []
  deleted_photos: JsaPhoto[] = []

  setViewModelFields()
  {     
    const today = DateTime.now().toFormat('M/d/yyyy')    
    const due = DateTime.fromISO(this.due_date, { zone: "utc" }).toFormat('M/d/yyyy')    

    this.due_date_vm = today == due ? 'Today': DateTime.fromISO(this.due_date, { zone: "utc" }).toFormat('M/d/yyyy')
    this.due_date_class_vm = today != due ? 'card-due-overdue' : 'card-due-due'
  }

  constructor() {
  }

  static buildOne(obj: any): Jsa {
    let target: Jsa = Object.assign(new Jsa(), obj)
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): Jsa[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Jsa.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

