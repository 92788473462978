import { Mrf } from "../models/mrf.model";
import { UuidAndId } from "../models/uuid-and-id";
import { UuidAndIdAndLineItems } from "../models/uuid-and-id-and-line-items";
import { MrfStateModel } from "../state/mrf.state";

export namespace MrfActions {
    export class Refresh {
        static readonly type = '[API-MRF] Refresh'
        constructor() {}
    }
    export class IncrementPendingCount {
        static readonly type = '[Network Service-MRF] Increment Pending Count'
        constructor() {}
    } 
    export class Init {
        static readonly type = '[API-MRF] Init'
        constructor(public payload: MrfStateModel) {}
    }
    export class Set {
        static readonly type = '[API-MRF] Set'
        constructor(public payload: Mrf[]) {}
    }
    export class Merge {
        static readonly type = '[API-MRF] Merge'
        constructor(public payload: Mrf[]) {}
    }
    export class AddOne {
        static readonly type = '[UI-MRF] Add One'
        constructor(public payload: Mrf) {}
    }       
    export class UpdateOne {
        static readonly type = '[UI-MRF] Update One'
        constructor(public payload: Mrf) {}
    }       
    export class DeleteOne {
        static readonly type = '[UI-MRF] Delete One'
        constructor(public payload: Mrf) {}
    }  
    export class ApiAddedOne {
        static readonly type = '[API-MRF] Added One'
        constructor(public payload: UuidAndIdAndLineItems) {}
    }
    export class ApiUpdatedOne {
        static readonly type = '[API-MRF] Updated One'
        constructor(public payload: UuidAndIdAndLineItems) {}
    }
    export class ApiDeletedOne {
        static readonly type = '[API-MRF] Deleted One'
        constructor(public payload: UuidAndId) {}
    }
}
