import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Pto } from '../state-man/models/pto.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})

export class PtoService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
    ) {
  }

  public async getByUserId (user_id: number) : Promise<Pto | null> {
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'user/' + user_id)          
      return Pto.buildOne(ret)
    } catch (error) {
      LoggerService.log(error + 'user/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
      return null
    }
  }  
}
