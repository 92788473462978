import { DailyWork } from "../models/daily-work.model";
import { DailyWorkStateModel } from "../state/daily-work.state";

export namespace DailyWorkActions {
    export class Refresh {
        static readonly type = '[API-DailyWork] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-DailyWork] Init'
        constructor(public payload: DailyWorkStateModel) {}
    }
    export class Set {
        static readonly type = '[API-DailyWork] Set'
        constructor(public payload: DailyWork[]) {}
    }
}
