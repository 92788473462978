import { Wor } from "../models/wor.model";
import { UuidAndId } from "../models/uuid-and-id";
import { WorStateModel } from "../state/wor.state";

export namespace WorActions {
    export class Refresh {
        static readonly type = '[API-WOR] Refresh'
        constructor() {}
    }
    export class IncrementPendingCount {
        static readonly type = '[Network Service-WOR] Increment Pending Count'
        constructor() {}
    } 
    export class Init {
        static readonly type = '[API-WOR] Init'
        constructor(public payload: WorStateModel) {}
    }
    export class Set {
        static readonly type = '[API-WOR] Set'
        constructor(public payload: Wor[]) {}
    }
    export class Merge {
        static readonly type = '[API-WOR] Merge'
        constructor(public payload: Wor[]) {}
    }
    export class AddOne {
        static readonly type = '[UI-WOR] Add One'
        constructor(public payload: Wor) {}
    }   
    export class UpdateOne {
        static readonly type = '[UI-WOR] Update One'
        constructor(public payload: Wor) {}
    }       
    export class DeleteOne {
        static readonly type = '[UI-WOR] Delete One'
        constructor(public payload: Wor) {}
    } 
    export class ApiAddedOne {
        static readonly type = '[API-WOR] Added One'
        constructor(public payload: UuidAndId) {}
    }
    export class ApiUpdatedOne {
        static readonly type = '[API-WOR] Updated One'
        constructor(public payload: UuidAndId) {}
    }
    export class ApiDeletedOne {
        static readonly type = '[API-WOR] Deleted One'
        constructor(public payload: UuidAndId) {}
    }
}
