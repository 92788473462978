import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { WorkOrder } from '../models/work-order.model';
import { WorkOrderActions } from '../actions/work-order.actions';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { StorageService } from 'src/app/services/storage.service';

export class WorkOrderStateModel {
    is_refreshing: boolean
    work_order: WorkOrder[]
}

@State<WorkOrderStateModel>({
    name: 'work_order',
    defaults: {
        is_refreshing: true,
        work_order: []
    }
})
@Injectable()
export class WorkOrderState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private workOrderService: WorkOrderService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<WorkOrderStateModel>) {                
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {    
        if (this.storageService.is_initialized) {
            const state_model: WorkOrderStateModel = change.currentValue
            this.storageService.set('work_orders', JSON.stringify(state_model))    
        }              
    }

    async reloadStateFromStorage() {        
        const json = await this.storageService.get('work_orders');        
        if (json) {
            const state_model: WorkOrderStateModel = JSON.parse(json)
            if (state_model) {
                this.store.dispatch(new WorkOrderActions.Init(state_model));
                return
            }
        }
        this.store.dispatch(new WorkOrderActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.workOrderService.getScheduled()    
        if (objs != null) {
          const wo = WorkOrder.buildMany(objs)                  
          this.store.dispatch(new WorkOrderActions.Set(wo))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: WorkOrderStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: WorkOrderStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getScheduledAll(state: WorkOrderStateModel) {
        return state.work_order
    }

    @Action(WorkOrderActions.Refresh)
    async refresh({patchState}: StateContext<WorkOrderStateModel>, {  }:WorkOrderActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(WorkOrderActions.Init)
    init({setState, getState}: StateContext<WorkOrderStateModel>, { payload }:WorkOrderActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new WorkOrderActions.Refresh())
    }
    
    @Action(WorkOrderActions.Set)
    set({setState}: StateContext<WorkOrderStateModel>, { payload }:WorkOrderActions.Set) {             
        setState({       
            is_refreshing: false,     
            work_order: payload
        })
    }
}
